import React from 'react';
import { Modal, Table, Spin } from 'antd';
import { renderTimeWithTimeZone } from '../utils';
function AccountReceivableAndRiskControlOperationLogModal ({open, setOpen, data, loading}) {
    const columns = [
        {
            title: '操作人',
            dataIndex: ['user', 'username'],
            key: 'username',
            width: '20%',
        },
        {
            title: '操作时间',
            dataIndex: 'time',
            key: 'time',
            width: '40%',
            render: renderTimeWithTimeZone,
        },
        {
            title: '描述',
            dataIndex: ['description'],
            key: 'description',
            width: '40%',
        },
    ]
    return (
        <div>
            <Modal
                title="操作日志"
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
                width={800}
            >
                <Spin spinning={loading}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowKey="id"
                        pagination={false}
                        scroll={{ y: 400 }}
                    />
                </Spin>
            </Modal>
        </div>
    )
}

export default AccountReceivableAndRiskControlOperationLogModal;