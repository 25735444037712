import React, { useEffect, useState } from 'react';
import { Modal, Form, Select, Row, Col, InputNumber, Button, notification } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useAdjustSurchargesMutation } from '../../features/api/apiSlice';
import { useGetChargeCodeQuery } from '../../features/api/apiSlice';
import DeleteSurchargeWithDisputeModal from './deleteSurchargeWithDisputeModal';
import axios from 'axios';
function AdjustSurchargeModal({ open, setOpen, chargeCodeData, selectedRowKeys, setSelectedRowKeys, setSelectedRows }) {

    const [form] = Form.useForm();
    const [deleteSurchargeWithDisputeModalOpen, setDeleteSurchargeWithDisputeModalOpen] = useState(false);
    const [disputeBasis, setDisputeBasis] = useState('');
    const { data: allChargeCodeData } = useGetChargeCodeQuery();
    const [adjustSurcharges, {
        error: adjustSurchargesError,
        isLoading: adjustSurchargesIsLoading,
        isSuccess: adjustSurchargesIsSuccess,
        isError: adjustSurchargesIsError,
    }] = useAdjustSurchargesMutation();

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 24, offset: 0 },
        },
    };

    useEffect(() => {
        if (adjustSurchargesIsSuccess) {
            setOpen(false);
            form.resetFields();
            setSelectedRowKeys([]);
            setSelectedRows([]);
            notification.success({
                message: 'Success',
                description: '编辑成功!',
            })
        }
        if (adjustSurchargesIsError) {
            notification.error({
                messgae: 'Error',
                description: adjustSurchargesError?.data?.error ?? '编辑失败',
            })
        }
    }, [adjustSurchargesIsSuccess, adjustSurchargesIsError])

    const onSubmitWithoutDispute = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                formData.append('ids', selectedRowKeys);
                formData.append('adjusted_surcharges', JSON.stringify(values.adjusted_surcharges));
                adjustSurcharges(formData);
            }).catch((info) => {
                console.log('Validate Failed:', info);
            })
    }
    const onSubmitWithDispute = () => {
        form.validateFields()
            .then((values) => {
                const formData = new FormData();
                formData.append('ids', selectedRowKeys);
                formData.append('adjusted_surcharges', JSON.stringify(values.adjusted_surcharges));
                formData.append('dispute_basis', disputeBasis);
                adjustSurcharges(formData);
            }).catch((info) => {
                console.log('Validate Failed:', info);
            })
    }

    const modalOkHandler = () => {
        form.validateFields()
            .then((values) => {
                // check if values.adjusted_surcharges has any action which is remove
                const hasRemoveAction = values.adjusted_surcharges.some((item) => item.action === 'remove');
                if (hasRemoveAction) {
                    // get the charge description of all removed surcharge
                    const removedSurchargeDescriptions = values.adjusted_surcharges
                        .filter((item) => item.action === 'remove')
                        .map((item) => item.charge_description);
                    // concatenate all removed charge_description
                    const disputeBasis = removedSurchargeDescriptions.join(',');
                    const formData = new FormData();
                    formData.append('ids', disputeBasis);
                    formData.append('detail_ids', selectedRowKeys);
                    formData.append('adjusted_surcharges', JSON.stringify(values.adjusted_surcharges));
                    axios.post('/accounts/acquire-charge-description-from-id/', formData)
                        .then((response) => {
                            setDisputeBasis(response.data.data);
                            setDeleteSurchargeWithDisputeModalOpen(true);
                        }).catch((err) => {
                            console.log(err);
                            notification.error({
                                message: 'Error',
                                description:  err?.response?.data?.error || '获取Dispute原因失败, 请稍后再试',
                            })
                        })
                } else {
                    const formData = new FormData();
                    formData.append('ids', selectedRowKeys);
                    formData.append('adjusted_surcharges', JSON.stringify(values.adjusted_surcharges));
                    adjustSurcharges(formData);
                }
            }).catch((info) => {
                console.log('Validate Failed:', info);
            })
    }

    return (
        <Modal
            title="编辑附加费"
            open={open}
            width={800}
            onCancel={() => setOpen(false)}
            onOk={modalOkHandler}
            confirmLoading={adjustSurchargesIsLoading}
        >
            <Form
                form={form}
                name="dynamic_form_item"
                initialValues={{ adjusted_surcharges: [""]}}
                {...formItemLayoutWithOutLabel}
            >
                <Form.List name='adjusted_surcharges'>
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row gutter={[0, 4]}>
                                    <Col span={4}>
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur', 'onSubmit']}
                                            label=""
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入或者删除该行",
                                                },
                                            ]}
                                            name={[index, 'action']}
                                        >
                                            <Select
                                                placeholder='Action'
                                                style={{ width: '90%' }}
                                                >
                                                <Select.Option value="add">增加</Select.Option>
                                                <Select.Option value="remove">删除</Select.Option>
                                                </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={15}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, curValues) => prevValues?.adjusted_surcharges?.[index]?.action !== curValues?.adjusted_surcharges?.[index]?.action}
                                            >
                                                { ({ getFieldValue }) => {
                                                const currAction = getFieldValue(['adjusted_surcharges', index, 'action']);
                                                if (currAction === 'remove') {
                                                    return <Form.Item
                                                    {...field}
                                                    label=""
                                                    validateTrigger={['onChange', 'onBlur', 'onSubmit']}
                                                    name={[index, 'charge_description']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "请输入或者删除该行",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        placeholder='Surcharge Description'
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '')
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase())
                                                        }
                                                        style={{ width: '90%' }}
                                                    >
                                                        {allChargeCodeData?.map((item) => (
                                                            <Select.Option key={item.id} value={item.id} label={item.description}>
                                                                {item.description}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                } else {
                                                    return <Form.Item
                                                    {...field}
                                                    label=""
                                                    validateTrigger={['onChange', 'onBlur', 'onSubmit']}
                                                    name={[index, 'charge_description']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "请输入或者删除该行",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        placeholder='Surcharge Description'
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '')
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase())
                                                        }
                                                        style={{ width: '90%' }}
                                                    >
                                                        {chargeCodeData?.map((item) => (
                                                            <Select.Option key={item.id} value={item.id} label={item.description}>
                                                                {item.description}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>;
                                                }
                                            }}

                                            </Form.Item>
                                        
                                    </Col>
                                    <Col span={4}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) => prevValues?.adjusted_surcharges?.[index]?.action !== curValues?.adjusted_surcharges?.[index]?.action}
                                        >
                                            { ({ getFieldValue }) => {
                                                const currAction = getFieldValue(['adjusted_surcharges', index, 'action']);
                                                if (currAction === 'add') {
                                                    return <Form.Item
                                                    {...field}
                                                    label=""
                                                    validateTrigger={['onChange', 'onBlur', 'onSubmit']}
                                                    name={[index, 'amount']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "请输入或者删除该行",
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber placeholder='请输入金额' />
                                                </Form.Item>
                                                } else {
                                                    return null;
                                                }
                                            }}
                                        </Form.Item>
                                        
                                    </Col>
                                    <Col span={1}>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '60%' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
            <DeleteSurchargeWithDisputeModal
                open={deleteSurchargeWithDisputeModalOpen}
                setOpen={setDeleteSurchargeWithDisputeModalOpen}
                disputeBasis={disputeBasis}
                setDisputeBasis={setDisputeBasis}
                onSubmitWithoutDispute={onSubmitWithoutDispute}
                onSubmitWithDispute={onSubmitWithDispute}
                />
        </Modal>
    )
}

export default AdjustSurchargeModal;