import React, { useState, useEffect } from 'react';
import { ProTable } from '@ant-design/pro-components';
import {
    useGetAccountReceivableQuery,
    useGetUserByDepartmentQuery,
    useGetAllCustomerUidQuery,
    useEditingAccountReceivableMutation,
    useGetAccountReceivableAndRiskControlOperationLogQuery,
} from '../../features/api/apiSlice';
import {Select, DatePicker, Input, Button, notification, Space} from 'antd';
import CreateNewModal from './CreateNewModal';
import PaymentModal from './PaymentModal';
import RevenueConfirmModal from './RevenueConfirmModal';
import AccountingConfirmModal from './AccountingConfirmModal';
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { addThousandsSeparator } from '../utils';
import DisplayReceivableEditingModal from "./DisplayReceivableEditingModal";
import AccountReceivableAndRiskControlOperationLogModal from "./AccountReceivableAndRiskControlOperationLogModal";
import ShowPaymentModal from './ShowPaymentModal';
import CustomizedButton from '../CustomizedComponents/CustomizedButton';
const { Option } = Select;
const { RangePicker } = DatePicker;
function AccountReceivableAndRiskControlPage() {
    const [searchData, setSearchData] = useState({});
    const { data, error, isLoading, isFetching, refetch } = useGetAccountReceivableQuery({ ...searchData });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentInvoiceId, setCurrentInvoiceId] = useState(null);
    const { data: billingUserData, error: billingUserError, isLoading: billingUserIsLoading, refetch: refetchBillingUserData } = useGetUserByDepartmentQuery(2);
    const { data: kaUserData, error: kaUserError, isLoading: kaUserIsLoading, refetch: refetchKaUserData } = useGetUserByDepartmentQuery(1);
    const {
        data: AccountReceivableAndRiskControlOperationLogData,
        isLoading: accountReceivableAndRiskControlOperationLogLoading,
        isFetching: accountReceivableAndRiskControlOperationLogFetching,
        refetch: accountReceivableAndRiskControlOperationLogRefetch
    } = useGetAccountReceivableAndRiskControlOperationLogQuery(currentInvoiceId);
    const { data: customerUidData } = useGetAllCustomerUidQuery();
    const [EditingAccountReceivable, {
        isLoading: editingAccountReceivableLoading,
        isSuccess: isEditingAccountReceivableSuccess,
        isError: isEditingAccountReceivableError,
        error: editingAccountReceivableError,
    }] = useEditingAccountReceivableMutation();
    const [createNewModalOpen, setCreateNewModalOpen] = useState(false);
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [paymentModalData, setPaymentModalData] = useState(undefined);
    const [revenueConfirmModalOpen, setRevenueConfirmModalOpen] = useState(false);
    const [accountingConfirmModalOpen, setAccountingConfirmModalOpen] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [displayEditModalOpen, setDisplayEditModalOpen] = useState(false);
    const [accountReceivableAndRiskControlOperationLogOpen, setAccountReceivableAndRiskControlOperationLogOpen] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showPaymentModalData, setShowPaymentModalData] = useState([]);
    const [paymentCancellationButtonLoading, setPaymentCancellationButtonLoading] = useState(false);
    const auth = useSelector((store) => store.auth.user);

    useEffect(() => {
        refetch();
    }, [])
    useEffect(() => {
        if (isEditingAccountReceivableSuccess) {
            const selectedItem = data ? data.results.find(item => item.id === currentInvoiceId) : null;
            let invoice_name = selectedItem.invoice_number
            notification.success({
                message: 'Success',
                description: `编辑账单: ${invoice_name} 成功!`,
            })
            setSelectedRowKeys([]);
            setDisplayEditModalOpen(false);
        }
        if (isEditingAccountReceivableError) {
            notification.error({
                message: 'Error',
                description: editingAccountReceivableError?.data?.error ?? '异常报错,编辑账单失败!',
            })
        }
    }, [isEditingAccountReceivableSuccess, isEditingAccountReceivableError, editingAccountReceivableError])
    const columns = [
        {
            title: '状态',
            dataIndex: 'account_invoice_status',
            key: 'account_invoice_status',
            search: true,
            fixed: 'left',
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="请选择"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    <Option label='待回款' key='0' value='0'>待回款</Option>
                    <Option label='已付款' key='1' value='1'>已付款</Option>
                </Select>
            }
        },
        {
            title: '业务类型',
            dataIndex: ['customer', 'business_type'],
            key: 'business_type',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="请选择"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    <Select.Option key='0' label='渠道' value="渠道">渠道</Select.Option>
                    <Select.Option key='1' label='转运' value="转运">转运</Select.Option>
                    <Select.Option key='2' label='代发' value="代发">代发</Select.Option>
                    <Select.Option key='3' label='提柜' value="提柜">提柜</Select.Option>
                    <Select.Option key='4' label='海运' value="海运">海运</Select.Option>
                </Select>
            }
        },
        {
            title: '出账日期',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
            }
        },
        {
            title: 'UID',
            dataIndex: ['customer', 'uid'],
            key: 'customer',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="Select a UID"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    mode="multiple"
                    style={{ textAlign: "left" }}
                >
                    {customerUidData && customerUidData.map((item) => {
                        return <Option key={item.uid} value={item.id}>{item.uid}</Option>
                    })}
                </Select>
            }
        },
        {
            title: '客户名',
            dataIndex: ['customer', 'username'],
            key: 'customer_name',
            search: true,
        },
        {
            title: '账单名',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Input placeholder='请输入' />
            }
        },
        {
            title: '账单金额',
            dataIndex: 'invoice_amount',
            key: 'invoice_amount',
            search: false,
            render: addThousandsSeparator,
        },
        {
            title: '应收余额',
            dataIndex: 'unpaid_total',
            key: 'unpaid_total',
            search: false,
            render: addThousandsSeparator,
        },
        {
            title: '逾期天数',
            dataIndex: 'overdue_days',
            key: 'overdue_days',
            search: false,
        },
        {
            title: '账期类型',
            dataIndex: 'billing_cycle',
            key: 'billing_cycle',
            search: true,
            render: (text, record) => {
                if (text === '预充') {
                    return <div>预充</div>
                } else {
                    return <div>{text} 天</div>
                }
            },
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="请选择"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    <Select.Option value="0">0 天</Select.Option>
                    <Select.Option value="7">7 天</Select.Option>
                    <Select.Option value="15">15 天</Select.Option>
                    <Select.Option value="25">25 天</Select.Option>
                    <Select.Option value="30">30 天</Select.Option>
                    <Select.Option value="60">60 天</Select.Option>
                    <Select.Option value="90">90 天</Select.Option>
                    <Select.Option value="预充">预充</Select.Option>
                </Select>
            }
        },
        {
            title: '出账主体',
            dataIndex: 'remittee',
            key: 'remittee',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="请选择"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    <Option label='Awesung Limited (新火傲盛科技有限公司)' key='0' value='0'>Awesung Limited (新火傲盛科技有限公司)</Option>
                    <Option label='广州奥越国际物流有限公司-海运' key='1' value='1'>广州奥越国际物流有限公司-海运</Option>
                    <Option label='广州辕蓬物流有限公司' key='2' value='2'>广州辕蓬物流有限公司</Option>
                    <Option label='新Awesung Tech' key='3' value='3'>新Awesung Tech</Option>
                    <Option label='Bloomsung' key='4' value='4'>Bloomsung</Option>
                    <Option label='深圳新火傲盛科技有限公司-深圳易仓' key='5' value='5'>深圳新火傲盛科技有限公司-深圳易仓</Option>
                    <Option label='老Awesung Inc' key='6' value='6'>老Awesung Inc</Option>
                </Select>
            }
        },
        {
            title: '出账人',
            dataIndex: 'biller',
            key: 'biller',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="请选择"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    {billingUserData && billingUserData.map((user) => (
                        <Option key={user.id} label={user.username} value={user.id}>{user.username}</Option>
                    ))}
                </Select>
            }
        },
        {
            title: '客户对接人',
            dataIndex: ['customer', 'contact', 'username'],
            key: 'contact',
            search: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    showSearch
                    allowClear
                    placeholder="请选择"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ textAlign: "left" }}
                >
                    {kaUserData && kaUserData.map((user) => (
                        <Option key={user.id} label={user.username} value={user.id}>{user.username}</Option>
                    ))}
                </Select>
            }
        },
        {
            title: '币种',
            dataIndex: 'currency',
            key: 'currency',
            search: false,
        },
        {
            title: '账期到期日',
            dataIndex: 'due_date',
            key: 'due_date',
            search: false,
        },
        
        {
            title: '已回款金额',
            dataIndex: 'payment_total',
            key: 'payment_total',
            search: false,
            render: addThousandsSeparator,
        },
        {
            title: '回款币种',
            dataIndex: 'payment_currency',
            key: 'payment_currency',
            search: false,
        },
        
        {
            title: '统一美元应收金额',
            dataIndex: 'unpaid_total_usd',
            key: 'unpaid_total_usd',
            search: false,
            render: addThousandsSeparator,
        },
        {
            title: '汇率',
            dataIndex: 'exchange_rate',
            key: 'exchange_rate',
            search: false,
        },
        {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
            search: false,
        },
        {
            title: '经营收入确认月份',
            dataIndex: 'revenue_confirm_date',
            key: 'revenue_confirm_date',
            search: true,
            render: (text, record) => {
                // render only the YYYY-MM
                return <div>{text.substring(0, 7)}</div>
            },
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <DatePicker picker='month' style={{ width: '100%' }} />
            }
        },
        {
            title: '财务核算确认月份',
            dataIndex: 'accounting_confirm_date',
            key: 'accounting_confirm_date',
            search: true,
            render: (text, record) => {
                // render only the YYYY-MM
                return <div>{text.substring(0, 7)}</div>
            },
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <DatePicker picker='month' style={{ width: '100%' }} onChange={(date, dateString) => {
                    const formattedDate = date ? dayjs(date).set('date', 1) : undefined;
                    form.setFieldsValue({ [item.dataIndex]: formattedDate });
                }}/>
            }
        },
        {
            title: '逾期天数',
            dataIndex: 'overdue_days',
            key: 'overdue_days',
            search: true,
            hidden: true,
            renderFormItem: (item, { defaultRender, ...rest }, form) => {
                return <Select
                    allowClear
                    placeholder="请选择"
                    style={{ textAlign: "left" }}>
                        <Option label='1-15' key='0' value='0'>1-15 天</Option>
                        <Option label='16-30' key='1' value='1'>16-30 天</Option>
                        <Option label='31-60' key='2' value='2'>31-60 天</Option>
                        <Option label='61-90' key='3' value='3'>61-90 天</Option>
                        <Option label='90+' key='4' value='4'>90+ 天</Option>
                    </Select>
            }
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            search: false,
            render: (text, record) => {
                return (
                    <Space size="small">
                        <Button type='link' onClick={() => paymentButtonHandler(record)}>收款</Button>
                        <Button type="link" onClick={() => displayEditButtonHandler(record)}>编辑</Button>
                        <Button type="link" onClick={() => AccountReceivableAndRiskControlOperationLogButtonHandler(record)}>日志</Button>
                        <Button type="link" loading={paymentCancellationButtonLoading} onClick={() => paymentCancellationButtonHandler(record)}>回款撤回</Button>
                    </Space>
                )
            }
        }
    ]

    const paymentCancellationButtonHandler = (record) => {
        setPaymentCancellationButtonLoading(true);
        axios.get(`/accounts/show-payments/?account_invoice=${record.id}`).then((res) => {
            setPaymentCancellationButtonLoading(false);
            setShowPaymentModal(true);
            setShowPaymentModalData(res.data.results);
        }).catch((err) => {
            console.log(err);
            setPaymentCancellationButtonLoading(false);
            notification.error({
                message: '失败',
                description: '获取付款信息失败, 请稍后重试',
            })
        })
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        selectedRowKeys,
    };

    const paymentButtonHandler = (record) => {
        if (record.account_invoice_status === '已付款') {
            notification.error({
                message: '失败',
                description: '该账单已结清',
            })
        } else if (record.billing_cycle === '预充') {
            notification.error({
                message: '失败',
                description: '预充账单无需收款',
            })
        } else {
            setPaymentModalData(record);
            setPaymentModalOpen(true);
        }
    }
    const revenueConfirmButtonHandler = () => {
        if (selectedRows.length !== 1) {
            notification.error({
                message: '失败',
                description: '请选择单一账单进行收款',
            })
        } else {
            setRevenueConfirmModalOpen(true);
        }
    }

    const accountingConfirmButtonHandler = () => {
        if (selectedRows.length !== 1) {
            notification.error({
                message: '失败',
                description: '请选择单一账单进行收款',
            })
        } else {
            setAccountingConfirmModalOpen(true);
        }
    }
    const downloadButtonHandler = (searchConfig) => {
        setDownloading(true);
        if (selectedRowKeys.length !== 0) {
            const formData = new FormData();
            formData.append('account_invoice_ids', selectedRowKeys);
            axios.post('/accounts/get-account-receivable/?isDownload=1/', formData).then((res) => {
                notification.success({
                    message: '成功',
                    description: '导出成功, 请前往后台任务管理页面获取文件',
                })
                setDownloading(false);
            }).catch((err) => {
                notification.error({
                    message: '失败',
                    description: '导出失败',
                })
                console.log(err);
                setDownloading(false);
            })
        } else {
            searchConfig.form.validateFields().then((values) => {
                const params = new URLSearchParams();
                if (values.customer) { params.append('customer', values.customer) }
                if (values.billing_cycle) { params.append('billing_cycle', values.billing_cycle) }
                if (values.contact) { params.append('contact', values.contact) }
                if (values.biller) { params.append('biller', values.biller) }
                if (values.invoice_number) { params.append('invoice_number', values.invoice_number) }
                if (values.business_type) { params.append('business_type', values.business_type) }
                if (values.remittee) { params.append('remittee', values.remittee) }
                if (values.account_invoice_status) { params.append('account_invoice_status', values.account_invoice_status) }
                if (values.customer_name) { params.append('customer_name', values.customer_name) }
                if (values.overdue_days) { params.append('overdue_days', values.overdue_days) }
                if (values?.invoice_date) {
                    params.append('invoice_date_after', values.invoice_date[0].substring(0, 10));
                    params.append('invoice_date_before', values.invoice_date[1].substring(0, 10));
                }
                if (values?.revenue_confirm_date) {
                    params.append('revenue_confirm_date_after', values.revenue_confirm_date[0].substring(0, 10));
                    params.append('revenue_confirm_date_before', values.revenue_confirm_date[1].substring(0, 10));
                }
                if (values?.accounting_confirm_date) {
                    params.append('accounting_confirm_date_after', values.accounting_confirm_date[0].substring(0, 10));
                    params.append('accounting_confirm_date_before', values.accounting_confirm_date[1].substring(0, 10));
                }
                params.append('isDownload', 1);
                axios.post('/accounts/get-account-receivable/?' + params.toString()).then((res) => {
                    notification.success({
                        message: '成功',
                        description: '导出成功, 请前往后台任务管理页面获取文件',
                    })
                    setDownloading(false);
                }).catch((err) => {
                    notification.error({
                        message: '失败',
                        description: '导出失败',
                    })
                    console.log(err);
                    setDownloading(false);
                })
            }).catch((info) => {
                console.log('Validate Failed:', info);
            });

        }
    }

    const displayEditButtonHandler = (record) => {
        setCurrentInvoiceId(record.id);
        setDisplayEditModalOpen(true);
    }
    const AccountReceivableAndRiskControlOperationLogButtonHandler = (record) => {
        if (record.id === currentInvoiceId) {
            setAccountReceivableAndRiskControlOperationLogOpen(true);
            accountReceivableAndRiskControlOperationLogRefetch();
            return;
        }
        setCurrentInvoiceId(record.id);
        setAccountReceivableAndRiskControlOperationLogOpen(true);
    }
    const onEditingReceivableSubmit = (values) => {
        const request = {
            ...values,
            invoice_id: currentInvoiceId,
        }
        EditingAccountReceivable(request);
    }
    return (
        <>
            <ProTable
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                    optionRender: (searchConfig, formProps, dom) => [
                        <Button
                            key="extraButton"
                            type="primary"
                            onClick={() => downloadButtonHandler(searchConfig, formProps)}
                            loading={downloading}
                        >
                            下载数据
                        </Button>,
                        ...dom,
                    ],
                }}
                request={(params) => {
                    setSearchData(params);
                }}
                rowKey={record => record.id}
                dataSource={data?.results}
                loading={isLoading || isFetching}
                columns={columns}
                rowSelection={rowSelection}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total: data?.count,
                }}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: () => refetch(),
                }}
                toolbar={{
                    actions: [
                        <CustomizedButton key='createNew' type='primary' onClick={() => setCreateNewModalOpen(true)}>
                            新建
                        </CustomizedButton>,
                        <Button key='revenueConfirm' type='primary' onClick={() => revenueConfirmButtonHandler()}>
                            经营收入月份确认
                        </Button>,
                        <Button key='accountingConfirm' type='primary' onClick={() => accountingConfirmButtonHandler()}>
                            财务核算
                        </Button>,
                    ],
                }}
            />
            <CreateNewModal
                open={createNewModalOpen}
                setOpen={setCreateNewModalOpen}
                customerUidData={customerUidData}
                billingUserData={billingUserData}
            />
            <PaymentModal
                open={paymentModalOpen}
                setOpen={setPaymentModalOpen}
                paymentModalData={paymentModalData}
                setPaymentModalData={setPaymentModalData}
            />
            <RevenueConfirmModal
                open={revenueConfirmModalOpen}
                setOpen={setRevenueConfirmModalOpen}
                selected={selectedRows[0]}
                setSelectedRowKeys={setSelectedRowKeys}
                setSelectedRows={setSelectedRows}
            />
            <AccountingConfirmModal
                open={accountingConfirmModalOpen}
                setOpen={setAccountingConfirmModalOpen}
                selected={selectedRows[0]}
                setSelectedRowKeys={setSelectedRowKeys}
                setSelectedRows={setSelectedRows}
            />
            <DisplayReceivableEditingModal
                selectedRowKey={currentInvoiceId}
                data={data}
                open={displayEditModalOpen}
                setOpen={setDisplayEditModalOpen}
                loading={editingAccountReceivableLoading}
                onSubmit={onEditingReceivableSubmit}
                success={isEditingAccountReceivableSuccess}
            />
            <AccountReceivableAndRiskControlOperationLogModal
                open={accountReceivableAndRiskControlOperationLogOpen}
                setOpen={setAccountReceivableAndRiskControlOperationLogOpen}
                data={AccountReceivableAndRiskControlOperationLogData?.data}
                loading={accountReceivableAndRiskControlOperationLogLoading || accountReceivableAndRiskControlOperationLogFetching}
            />
            <ShowPaymentModal
                open={showPaymentModal}
                setOpen={setShowPaymentModal}
                data={showPaymentModalData}
                refetch={refetch}
                />
        </>
    );
}

export default AccountReceivableAndRiskControlPage;