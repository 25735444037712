import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Form, Input, Select, Typography, Row, Col, Divider, Space, notification } from 'antd';
import { MinusCircleOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import './AddNewRuleExpressionModal.css';
const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;
function AddNewRuleExpressionModal({ open, setOpen, onSubmit, data, loading, isSuccess, disabled, chargeCodeData }) {
    const [form] = Form.useForm();
    const [ruleName, setRuleName] = useState(undefined);
    const [ruleDescription, setRuleDescription] = useState(undefined);
    
    useEffect(() => {
        if (open) {
            console.log('new data');
            setRuleName(data?.ruleName ?? undefined);
            setRuleDescription(data?.ruleDescription ?? undefined);
            form.setFieldsValue(data ?? {
                items: [{}]
            })
        }
    }, [open, data])
    const validators = {
        'region': (rule, value) => {
            // value cannot be numbers other than single digit 1-9
            if (value && !/^[1-9]$/.test(value)) {
                return Promise.reject('Region只能是1-9的正整数');
            }
            return Promise.resolve();
        },
        // TODO: 这个时间检测不应该放在这里，应该field1 为ship date时
        // 就转换成DatePicker，但是遇到一些更新问题，之后再看看怎么做
        'ship_date': (rule, value) => {
            if (value && !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
                return Promise.reject('日期格式不正确');
            }
            return Promise.resolve();
        },
    }
    useEffect(() => {
        setRuleName(undefined);
        setRuleDescription(undefined);
        form.resetFields();
    }, [isSuccess])
    const onFinish = (values) => {
        form.validateFields().then((values) => {
            if (!ruleName || ruleName.trim() === '') {
                return notification.error({
                    message: 'Error',
                    description: '规则名称不能为空',
                })
            }
            if (!values?.items || values.items.length === 0) {
                return notification.error({
                    message: 'Error',
                    description: '规则表达式不能为空',
                })
            }
            onSubmit({
                ruleName,
                ruleDescription,
                ...values
            });
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <Modal
            title="添加新规则"
            open={open}
            onOk={() => form.submit()}
            onCancel={() => setOpen(false)}
            width={1200}
            confirmLoading={loading}
        >
            <Title level={5}>基本信息</Title>
            <Space direction='vertical' size="middle" style={{ width: '50%', display: 'flex', justifyContent: 'space-evenly'}}>
                <Form.Item
                    label="规则名称"
                    required
                >
                    <Input
                        value={ruleName}
                        onChange={(event) => {
                            setRuleName(event.target.value);
                        }}
                        disabled={disabled} />
                </Form.Item>
                <Form.Item
                    label="规则描述"
                    value={ruleDescription}
                >
                    <TextArea
                        rows={4}
                        value={ruleDescription}
                        onChange={(event) => {
                            setRuleDescription(event.target.value);
                        }}
                        disabled={disabled} />
                </Form.Item>
            </Space>
            <Divider />
            <Title level={5}>前置条件</Title>
            <Form
                form={form}
                autoComplete="off"
                onFinish={onFinish}
                initialValues={data ?? {
                    items: [{}]
                }}
                disabled={disabled}
            >
                <Form.List name="items">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row gutter={8} key={field.key}>
                                    <Col span={3} className="middle-col">
                                        {index !== 0 && (
                                            <Form.Item
                                                className="middle-row"
                                                name={[field.name, 'logicOperator']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "该值不能为空",
                                                    },
                                                ]}>
                                                <Select placeholder="AND/OR">
                                                    <Option value='0'>AND</Option>
                                                    <Option value='1'>OR</Option>
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </Col>
                                    <Col span={21}>
                                        <Card
                                            title={`规则组`}
                                            size="small"
                                            key={field.key}
                                            className="custom-card"
                                            extra={
                                                <CloseOutlined
                                                    onClick={() => {
                                                        remove(field.name);
                                                    }}
                                                />
                                            }
                                            style={{
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <Form.Item>
                                                <Form.List name={[field.name, 'rules']} initialValue={[{}]}>
                                                    {(subFields, subOpt) => (
                                                        <>
                                                            {subFields.map((subField, subIndex) => (
                                                                <Row gutter={8} key={subField.key}>
                                                                    <Col span={3}>
                                                                        {subIndex !== 0 && (
                                                                            <Form.Item
                                                                                validateTrigger={['onSubmit']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "该值不能为空",
                                                                                    },
                                                                                ]}
                                                                                name={[subIndex, 'operator']}
                                                                                key={subField.key}
                                                                            >
                                                                                <Select placeholder="AND/OR">
                                                                                    <Option value='0'>AND</Option>
                                                                                    <Option value='1'>OR</Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        )}
                                                                    </Col>
                                                                    <Col span={2}>
                                                                        <Form.Item
                                                                            validateTrigger={['onSubmit']}
                                                                            name={[subIndex, 'not']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "该值不能为空",
                                                                                },
                                                                            ]}
                                                                            key={subField.key}
                                                                            initialValue="0"
                                                                        >
                                                                            <Select>
                                                                                <Option value='0'> </Option>
                                                                                <Option value='1'>Not</Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            validateTrigger={['onSubmit']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "该值不能为空",
                                                                                },
                                                                            ]}
                                                                            name={[subIndex, 'field1']}
                                                                            key={subField.key}
                                                                        >
                                                                            <Select
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    (option?.value ?? '')
                                                                                        .toLowerCase()
                                                                                        .includes(input.toLowerCase())
                                                                                }
                                                                            >
                                                                                <Option value='uid'>UID</Option>
                                                                                <Option value='shipment_date'>Ship Date</Option>
                                                                                <Option value='Region'>Region</Option>
                                                                                <Option value='Svc'>Service</Option>
                                                                                <Option value='to_zipcode_5'>Destination Zipcode 5</Option>
                                                                                <Option value='Dlvry Date'>Delivery Date</Option>
                                                                                {chargeCodeData?.map((item) => (
                                                                                    <Option value={item.description}>
                                                                                        {item.description}
                                                                                    </Option>
                                                                                ))}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={3}>
                                                                        <Form.Item
                                                                            validateTrigger={['onSubmit']}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "该值不能为空",
                                                                                },
                                                                            ]}
                                                                            name={[subIndex, 'symbol']}
                                                                            key={subField.key}
                                                                        >
                                                                            <Select 
                                                                                placeholder="数学符号">
                                                                                <Option value='0'>=</Option>
                                                                                <Option value='1'>&gt;</Option>
                                                                                <Option value='2'>&lt;</Option>
                                                                                <Option value='3'>≥</Option>
                                                                                <Option value='4'>≤</Option>
                                                                                <Option value='5'>match</Option>
                                                                                <Option value='6'>in</Option>
                                                                                <Option value='7'>exists</Option>
                                                                                <Option value='8'>available</Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={5}>
                                                                        <Form.Item
                                                                            noStyle
                                                                            shouldUpdate={(prevValues, currentValues) => 
                                                                                prevValues.items?.[index]?.rules?.[subIndex]?.symbol !== currentValues.items?.[index]?.rules?.[subIndex]?.symbol ||
                                                                                prevValues.items?.[index]?.rules?.[subIndex]?.field1 !== currentValues.items?.[index]?.rules?.[subIndex]?.field1
                                                                            }
                                                                        >
                                                                            {({ getFieldValue }) => {
                                                                                const symbol = getFieldValue(['items', index, 'rules', subIndex, 'symbol']);
                                                                                const field1 = getFieldValue(['items', index, 'rules', subIndex, 'field1']);
                                                                                const field2Validator = validators[field1];
                                                                                return (7 === Number(symbol) || 8 === Number(symbol)) ? null : (
                                                                                        <Form.Item
                                                                                        validateTrigger={['onSubmit']}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: "该值不能为空",
                                                                                            },
                                                                                            {
                                                                                                validator: field2Validator,
                                                                                            }
                                                                                        ]}
                                                                                        name={[subIndex, 'field2']}
                                                                                        key={subField.key}
                                                                                        initialValue={undefined}
                                                                                    >
                                                                                        <Input defaultValue={undefined} placeholder="字段2" />
                                                                                    </Form.Item>
                                                                                );
                                                                            }}
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={1}>
                                                                        {subFields.length > 1 ? (
                                                                            <CloseOutlined
                                                                                onClick={() => {
                                                                                    subOpt.remove(subField.name);
                                                                                }}
                                                                            />
                                                                        ) : null}
                                                                    </Col>

                                                                </Row>
                                                            ))}
                                                            <Form.Item>
                                                                <Button
                                                                    type="dashed"
                                                                    onClick={() => subOpt.add()}
                                                                    style={{ width: '100%' }}
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    添加单条规则
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </Form.Item>
                                        </Card>
                                    </Col>
                                </Row>
                            ))}

                            <Button type="dashed" onClick={() => add()} block>
                                添加规则组
                            </Button>
                        </>
                    )}
                </Form.List>

                {/* <Form.Item noStyle shouldUpdate>
                    {() => (
                        <Typography>
                            <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                        </Typography>
                    )}
                </Form.Item> */}
            </Form>
        </Modal>
    )
}

export default AddNewRuleExpressionModal;