import React, { useState } from 'react';
import { Modal, Table, Button, Typography, notification } from 'antd';
import { renderDateTime } from '../utils';
import axios from 'axios';
const { Text } = Typography;
function ShowPaymentModal ({ open, setOpen, data, refetch }) {

    const [doubleConfirmModalOpen, setDoubleConfirmModalOpen] = useState(false);
    const [cancellationAmount, setCancellationAmount] = useState(0);
    const [cancellationId, setCancellationId] = useState(0);
    const cancellationButtonHandler = (record) => {
        setCancellationAmount(record.payment_amount);
        setCancellationId(record.id);
        setDoubleConfirmModalOpen(true);
    }
    const [requestLoading, setRequestLoading] = useState(false);

    const columns = [
        {
            title: '回款金额',
            dataIndex: 'payment_amount',
            key: 'payment_amount',
            width: '15%',
        },
        {
            title: '币种',
            dataIndex: 'payment_currency',
            key: 'payment_currency',
            width: '10%',
        },
        {
            title: '汇率',
            dataIndex: 'exchange_rate',
            key: 'exchange_rate',
            width: '15%',
        },
        {
            title: '回款主体',
            dataIndex: 'payee',
            key: 'payee',
            width: '20%',
        },
        {
            title: '操作人',
            dataIndex: ['user', 'username'],
            key: 'user',
            width: '10%',
        },
        {
            title: '操作时间',
            dataIndex: 'created_at',
            key: 'created_at',
            render: renderDateTime,
            width: '20%',
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            width: '10%',
            render: (text, record, index) => (
                <Button type='link' onClick={() => cancellationButtonHandler(record)}>撤回</Button>
            )
        }
    ]

    const onSubmit = () => {
        setRequestLoading(true);
        const formData = new FormData();
        formData.append('payment_id', cancellationId);
        axios.post('/accounts/cancel-payment/', formData).then(res => {
            setRequestLoading(false);
            notification.success({
                message: 'Success',
                description: '回款撤回成功'
            })
            setDoubleConfirmModalOpen(false);
            setOpen(false);
            refetch();
        }).catch(err => {
            setRequestLoading(false);
            console.error(err);
            if (err.response?.status === 400) {
                return notification.error({
                    message: 'Error',
                    description: err.response?.data?.error,
                })
            }
        })
    }

    return (
        <div>
        <Modal
            title='回款撤回'
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width={800}
            confirmLoading={requestLoading}
            >
                <Table
                    dataSource={data}
                    columns={columns}
                    size='small'
                    pagination={false}
                    />
            </Modal>
            <Modal
                title='撤回'
                open={doubleConfirmModalOpen}
                onCancel={() => setDoubleConfirmModalOpen(false)}
                onOk={onSubmit}
                confirmLoading={requestLoading}
                >
                    <Text>请确认撤回金额为 {cancellationAmount} 的回款</Text>
                </Modal>
            </div>
    )
}

export default ShowPaymentModal;