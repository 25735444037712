import React from 'react';
import { Link } from 'react-router-dom';
import {
  PlusSquareOutlined,
  HomeOutlined,
  AppstoreOutlined,
  SettingOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import axios from 'axios';
import { logoutUser } from "./features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const { Sider } = Layout;

const NavBar = ({ collapsed, onCollapse }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pagePermissions = useSelector(state => state.auth.user.page_permission);

  const logoutLinkClickHandler = () => {
    axios('/accounts/logout/', {
      method: "post",
      withCredentials: true
    }).then((res) => {
      dispatch(logoutUser());
      navigate('/login');
    }).catch((err) => {
    });
  }

  const items = [
    {
      key: '1',
      icon: React.createElement(HomeOutlined),
      label: <Link to="home">首页</Link>,
      permission: 'Homepage',
    },
    {
      key: '2',
      icon: React.createElement(AppstoreOutlined),
      label: <Link to="logistics">物流管理</Link>,
      permission: 'LogisticsPage',
    },
    {
      key: '3',
      icon: React.createElement(PlusSquareOutlined),
      label: <Link to="clients">客户管理</Link>,
      permission: 'ClientPage',
    },
    {
      key: '4',
      icon: React.createElement(PlusSquareOutlined),
      label: <Link to="quoteSheet">报价表管理</Link>,
      permission: 'QuoteSheetPage',
    },
    {
      key: '5',
      icon: React.createElement(PlusSquareOutlined),
      label: "账单管理",
      permission: 'BillingManagementPage',
      children: [
        {
          key: '6-1',
          label: <Link to="bills/createNewBatch">新建导入批次</Link>,
          permission: 'CreateNewBatchPage',
        },
        {
          key: '6-2',
          label: <Link to="bills/batchManagement">导入批次管理</Link>,
          permission: 'BatchManagementPage',
        },
        {
          key: '6-4',
          label: <Link to="bills/errorHandling">账单异常处理</Link>,
          permission: 'ErrorHandlingPage',
        },
        {
          key: '6-5',
          label: <Link to="bills/groundCalculation">Ground出账管理</Link>,
          permission: 'GroundCalculationPage',
        },
        {
          key: '6-3',
          label: <Link to="bills/billingDetails">账单明细管理</Link>,
          permission: 'BillingDetailsPage',
        },
        {
          key: '6-9',
          label: <Link to="bills/HAndSpCalculation">H&SP出账管理</Link>,
          permission: 'HAndSpCalculationPage',
        },
        {
          key: '6-6',
          label: <Link to="bills/nonStandardService">非常用渠道管理</Link>,
          permission: 'NonStandardServicePage',
        },
        {
          key: '6-7',
          label: <Link to="bills/nonOriginalBillingDetails">非Original账单明细管理</Link>,
          permission: 'NonOriginalBillingDetailsPage',
        },
        {
          key: '6-8',
          label: <Link to="bills/billingBatch">出账批次管理</Link>,
          permission: 'BillingBatchPage',
        },
      ]
    },
    {
      key: '10088',
      icon: React.createElement(PlusSquareOutlined),
      label: "应收风控",
      permission: 'RiskControlManagementPage',
      children: [{
        key: '10',
        // icon: React.createElement(MoneyCollectOutlined),
        label: <Link to="AccountReceivableAndRiskControl">应收风控管理</Link>,
        permission: 'AccountReceivableAndRiskControlPage',
      },
        {
          key: '10087',
          // icon: React.createElement(MoneyCollectOutlined),
          label: <Link to="ReceivablesRiskDashboard">应收风控看板</Link>,
          permission: 'ReceivablesRiskDashboardPage',
        },
      ],
    },


    {
      key: '9',
      icon: React.createElement(SettingOutlined),
      label: <Link to="RuleExpressionManagement">规则管理</Link>,
      permission: 'RuleExpressionManagementPage',
    },
    {
      key: '6',
      icon: React.createElement(PlusSquareOutlined),
      label: <Link to="asyncManagement">后台任务管理</Link>,
      permission: 'AsyncManagementPage',
    },
    {
      key: '7',
      icon: React.createElement(PlusSquareOutlined),
      label: <Link to="AWSFileManagement">AWS文件管理</Link>,
      permission: 'AWSFileManagementPage',
    },
    {
      key: '8',
      icon: React.createElement(PlusSquareOutlined),
      label: <Link to="fuelSurcharge">燃油附加费管理</Link>,
      permission: 'FuelSurchargePage',
    },
    {
      key: '10086',
      icon: React.createElement(PlusSquareOutlined),
      label: <Link onClick={logoutLinkClickHandler}>登出</Link>,
      permission: 'LogoutButton',
    },
  ]

  const filteredItems = items.filter(item => pagePermissions?.includes(item.permission))
  .map(item => ({
    ...item,
    children: item.children ? item.children.filter(child => pagePermissions?.includes(child.permission)) : null,
  }));
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
      width={300}
    >
      <Menu mode="inline" theme="dark" items={filteredItems} style={{ padding: '10px 0', fontSize: '20px' }} />
    </Sider>
  );
};
export default NavBar;