import React, { useEffect } from 'react';
import { Form, Modal, Select, Input, DatePicker, notification } from 'antd';
import dayjs from 'dayjs';
import { 
    useCreateAccountReceivableMutation,
} from '../../features/api/apiSlice';
const { Option } = Select;
function CreateNewModal({ open, setOpen, customerUidData, billingUserData }) {

    const [form] = Form.useForm();
    const [createAccountReceivable, {
            error: createAccountReceivableError,
            isLoading: createAccountReceivableIsLoading,
            isSuccess: createAccountReceivableIsSuccess,
            isError: createAccountReceivableIsError,
        }] = useCreateAccountReceivableMutation();
    const positiveNumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^-?[0-9]+(.[0-9]+)?$/.test(value)) {
              reject('请输入可识别的数字!');
            } else if (value && value.length > 20) {
                reject('最多20位!');
            } else {
                resolve();
            }
        });
    }
    useEffect(() => {
        if (createAccountReceivableIsSuccess) {
            setOpen(false);
            form.resetFields();
            notification.success({
                message: '成功',
                description: '创建成功',
            })
        }
        if (createAccountReceivableIsError) {
            notification.error({
                message: '失败',
                description: createAccountReceivableError?.data?.error || '创建失败',
            })
        }
    }, [createAccountReceivableIsSuccess, createAccountReceivableIsError]);
    const modalOkHandler = () => {
        form.validateFields().then((values) => {
            const formData = new FormData();
            formData.append('remittee', values.remittee);
            formData.append('customer', values.customer);
            formData.append('invoice_number', values.invoice_number);
            formData.append('invoice_amount', values.invoice_amount);
            formData.append('currency', values.currency);
            formData.append('biller', values.biller);
            formData.append('invoice_date', values.invoice_date.format('YYYY-MM-DD'));
            createAccountReceivable(formData);
        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    }
    return (
        <Modal
            title="新建"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={modalOkHandler}
            confirmLoading={createAccountReceivableIsLoading}
        >
            <Form
                form={form}
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '0%',
                    marginRight: '10%',
                    maxWidth: '100%'
                }}
            >
                <Form.Item label='出账主体' name='remittee' rules={[{ required: true, message: '请选择出账主体!' }]}>
                    <Select
                        showSearch
                        placeholder="请选择"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option label='Awesung Limited (新火傲盛科技有限公司)' key='0' value='0'>Awesung Limited (新火傲盛科技有限公司)</Option>
                        <Option label='广州奥越国际物流有限公司-海运' key='1' value='1'>广州奥越国际物流有限公司-海运</Option>
                        <Option label='广州辕蓬物流有限公司' key='2' value='2'>广州辕蓬物流有限公司</Option>
                        <Option label='新Awesung Tech' key='3' value='3'>新Awesung Tech</Option>
                        <Option label='Bloomsung' key='4' value='4'>Bloomsung</Option>
                        <Option label='深圳新火傲盛科技有限公司-深圳易仓' key='5' value='5'>深圳新火傲盛科技有限公司-深圳易仓</Option>
                        <Option label='老Awesung Inc' key='6' value='6'>老Awesung Inc</Option>
                    </Select>
                </Form.Item>
                <Form.Item label='客户' name='customer' rules={[{ required: true, message: '请选择出账客户!' }]}>
                    <Select
                        showSearch
                        placeholder="请选择"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {customerUidData && customerUidData.map((customer) => (
                            <Option key={customer.id} value={customer.id} label={customer.uid}>{customer.uid}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label='账单名' name='invoice_number' rules={[{ required: true, message: '请输入账单名!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label='账单金额' name='invoice_amount' rules={[{ required: true, message: '请输入账单金额!' }, { validator: positiveNumberOnly }]}>
                    <Input />
                </Form.Item>
                <Form.Item label='币种' name='currency' rules={[{ required: true, message: '请选择币种!' }]}>
                    <Select
                        showSearch
                        placeholder="请选择"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option label='USD' key='0' value='0'>USD</Option>
                        <Option label='CNY' key='1' value='1'>CNY</Option>
                        <Option label='EUR' key='2' value='2'>EUR</Option>
                        <Option label="CAD" key='3' value='3'>CAD</Option>
                    </Select>
                </Form.Item>
                <Form.Item label='出账人' name='biller' rules={[{ required: true, message: '请输入出账人!' }]}>
                    <Select
                        showSearch
                        placeholder="请选择"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {billingUserData && billingUserData.map((user) => (
                            <Option key={user.id} value={user.id} label={user.username}>{user.username}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label='出账日期' name='invoice_date' rules={[{ required: true, message: '请选择出账日期!' }]} initialValue={dayjs()}>
                    <DatePicker />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CreateNewModal;