import React, { useState, useEffect } from 'react';
import { Table, Space, Button, notification, Modal, Dropdown } from 'antd';
import { FixedTrackingAndSurchargeColumns } from "./ErrorHandlingTabColumns";
import {
    useDisputeBillingDetailsMutation,
    useStatusUpdateHandlerV2Mutation,
    useGetChargeCodeQuery,
    useSunkCostMutation,
} from '../../features/api/apiSlice';
import axios from 'axios';
import ProTable from '@ant-design/pro-table';
import MatchZZZModal from './MatchZZZModal';
import CompulsoryPaymentModal from './CompulsoryPaymentModal';
import DisputeBasisModal from './DisputeBasisModal';
import DisputeSurchargeModal from './DisputeSurchargeModal';
function SurchargeErrorTab({ data, loading, searchData, setSearchData, currentPage, setCurrentPage, pageSize, setPageSize, UIDData }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [disputeBasisModalOpen, setDisputeBasisModalOpen] = useState(false);
    const [surchargeErrorHandler, {
        isLoading: surchargeErrorLoading,
        isSuccess: isSurchargeErrorSuccess,
        isError: isSurchargeErrorError,
        error: surchargeErrorError,
    }] = useStatusUpdateHandlerV2Mutation();
    const [chargeCodeData, setChargeCodeData] = useState([]);
    const { data: allChargeCodeData } = useGetChargeCodeQuery();
    const [ZZZModalOpen, setZZZModalOpen] = useState(false);
    const [ZZZModalData, setZZZModalData] = useState(undefined);
    const [openSurcharge, setOpenSurcharge] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [compulsoryPaymentModalOpen, setCompulsoryPaymentModalOpen] = useState(false);
    const [compulsoryPaymentModalData, setCompulsoryPaymentModalData] = useState({});
    const [disputeSurchargeModalOpen, setDisputeSurchargeModalOpen] = useState(false);
    const [sunkCostHandler, {
        isLoading: sunkCostLoading,
        isSuccess: isSunkCostSuccess,
        isError: isSunkCostError,
        error: sunkCostError,
    }] = useSunkCostMutation();
    const [disputeBillingDetails, { 
        isLoading: disputeLoading,
        isSuccess: isDisputeSuccess,
        isError: isDisputeError,
        error: disputeError,
    }] = useDisputeBillingDetailsMutation();
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    useEffect(() => {
        if (isSunkCostSuccess) {
            notification.success({
                message: 'Success',
                description: '标记为Sunk Cost成功',
            })
            setSelectedRowKeys([]);
        }
        if (isSunkCostError) {
            notification.error({
                message: 'Error',
                description: sunkCostError?.data?.error ?? '标记为Sunk Cost失败',
            })
        }
    }, [isSunkCostSuccess, isSunkCostError, sunkCostError])
    useEffect(() => {
        if (isSurchargeErrorSuccess) {
            notification.success({
                message: 'Success',
                description: 'Surcharge error success',
            })
            setSelectedRowKeys([]);
        }
        if (isSurchargeErrorError) {
            notification.error({
                message: 'Error',
                description: surchargeErrorError?.data?.error ?? 'Surcharge error failed for unknown reason',
            })
        }
    }, [isSurchargeErrorSuccess, isSurchargeErrorError, surchargeErrorError])
    useEffect(() => {
        if (isDisputeSuccess) {
            notification.success({
                message: 'Success',
                description: 'Dispute success',
            })
            setSelectedRowKeys([]);
        }
        if (isDisputeError) {
            notification.error({
                message: 'Error',
                description: disputeError?.data?.error ?? 'Dispute failed for unknown reason',
            })
        }
    }, [isDisputeSuccess, isDisputeError, disputeError])
    const exceptionButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        surchargeErrorHandler({ ids: keys, })
    }

    const compulsoryPaymentButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length !== 1) {
            return notification.error({
                message: 'Error',
                description: '请选择单条记录',
            })
        }
        setCompulsoryPaymentModalData(selectedRows[0]);
        setCompulsoryPaymentModalOpen(true);
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        selectedRowKeys,
    };
    const matchZZZButtonHandler = () => {
        const keys = selectedRowKeys;
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
       
        if (selectedRows.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        // Total of all ‘Other Charge’ Components needs to be in any surcharge_json in selectedRows
        // and all the Total of all ‘Other Charge’ Components values should be the same
        const allExist = selectedRows.every(row => row.surcharge_json['Total of all ‘Other Charge’ Components']);
        if (!allExist) {
            return notification.error({
                message: 'Error',
                description: '请选择包含 Total of all ‘Other Charge’ Components 的条目',
            })
        }

        const totalOfAllOtherChargeComponents = selectedRows[0]?.surcharge_json['Total of all ‘Other Charge’ Components'];
        const isMatch = selectedRows.every(row => row.surcharge_json['Total of all ‘Other Charge’ Components'] === totalOfAllOtherChargeComponents);
        if (!isMatch) {
            return notification.error({
                message: 'Error',
                description: '请选择 Total of all ‘Other Charge’ Components 相同的条目',
            })
        }

        const totalOfAllOtherServiceComponents = selectedRows[0]?.logistics_service.id;
        const isServiceMatch = selectedRows.every(row => row.logistics_service.id === totalOfAllOtherServiceComponents);
        if (!isServiceMatch) {
            return notification.error({
                message: 'Error',
                description: '请选择渠道服务相同的条目',
            })
        }

        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('service', totalOfAllOtherServiceComponents);
        axios.get('/accounts/get-charge-code-by-service/', {
            params: urlSearchParams,
        }).then(response => {
            setChargeCodeData(response.data);
            setZZZModalOpen(true);
            setZZZModalData(selectedRows);
        }).catch(error => {
            setChargeCodeData([]);
            setZZZModalOpen(true);
            setZZZModalData(selectedRows);
            console.log(error);
            notification.error({
                message: 'Error',
                description: '获取对应附加费选项失败,请重新打开弹窗试试',
            })
        })
        
    }
    const columns = FixedTrackingAndSurchargeColumns(setOpenSurcharge, setModalContent);
    const surcharge_columns = [{
        title: '费用类型',
        dataIndex: 'type',
        key: 'type'
    }, {
        title: '金额',
        dataIndex: 'amount',
        key: 'amount'
    }];
    const renderModalTable = () => {
        return <Table columns={surcharge_columns} dataSource={modalContent} pagination={false} />;
    };
    const items = [
        {
            label: '标记为自用面单-Company',
            key: '1',
        },
        {
            label: '标记为自用面单-Personal',
            key: '2',
        },
    ];
    const disputeItems = [
        {
            label: 'Dispute整条',
            key: '1',
        },
        {
            label: 'Dispute附加费',
            key: '2',
        }
    ]
    const handleMenuClick = (e) => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (e.key === '1') {
            const request = {
                ids: selectedRowKeys,
                to_uid: '0005',
                flag: false,
            };
            sunkCostHandler(request);
        }
        if (e.key === '2') {
            const request = {
                ids: selectedRowKeys,
                to_uid: '0007',
                flag: false,
            };
            sunkCostHandler(request);
        }
    };
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const handleDisputeMenuClick = (e) => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (UIDData?.count !== 0) {
            return notification.error({
                message: 'Error',
                description: '执行失败,请优先处理完UID异常再执行其他操作!',
            })
        }
        if (e.key === '1') {
            setDisputeBasisModalOpen(true);
        }
        if (e.key === '2') {
            setDisputeSurchargeModalOpen(true);
        }
    }

    const disputeMenuProps = {
        items: disputeItems,
        onClick: handleDisputeMenuClick,
    }


    return (
        <Space direction="vertical" style={{ display: 'flex' }}>
            <ProTable
                search={false}
                columns={columns}
                rowKey={record => record.id}
                rowSelection={rowSelection}
                loading={loading}
                dataSource={data?.results}
                pagination={{
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: currentPage,
                    total: data?.count,
                    onChange: (page, pageSize) => {
                        setCurrentPage(page);
                        setPageSize(pageSize);
                        setSelectedRowKeys([]);
                    },
                    onShowSizeChange: (current, size) => {
                        setCurrentPage(current);
                        setPageSize(size);
                        setSelectedRowKeys([]);
                    },
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: false,
                }}
                toolbar={{
                    title: ' ',
                    search: false,
                    actions: [
                        <Button loading={surchargeErrorLoading} type="primary" onClick={exceptionButtonHandler}>异常已修复</Button>,
                        <Dropdown menu={menuProps}>
                            <Button type="primary" loading={sunkCostLoading}>标记</Button>
                        </Dropdown>,
                        <Button type="primary" onClick={compulsoryPaymentButtonHandler}>强制收费</Button>,
                        <Dropdown menu={disputeMenuProps}>
                            <Button loading={disputeLoading}>Dispute</Button>
                        </Dropdown>,
                        <Button onClick={matchZZZButtonHandler}>匹配 Total of all `Other Charge` Components</Button>
                    ],
                }}
            />
            <MatchZZZModal
                open={ZZZModalOpen}
                setOpen={setZZZModalOpen}
                modalData={ZZZModalData}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                setSelectedRows={setSelectedRows}
                chargeCodeData={chargeCodeData}
                />
            <CompulsoryPaymentModal
                open={compulsoryPaymentModalOpen}
                setOpen={setCompulsoryPaymentModalOpen}
                data={compulsoryPaymentModalData}
                setSelectedRowKeys={setSelectedRowKeys}
                setSelectedRows={setSelectedRows}
            />
            <Modal
                title="附加费详情"
                open={openSurcharge}
                onOk={() => setOpenSurcharge(false)}
                onCancel={() => setOpenSurcharge(false)}
                width={800}
            >
                {renderModalTable()}
            </Modal>
            <DisputeBasisModal
                open={disputeBasisModalOpen}
                setOpen={setDisputeBasisModalOpen}
                currentTab='surchargeError'
                disputeBillingDetails={disputeBillingDetails}
                keys={selectedRowKeys}
                />
            <DisputeSurchargeModal
                open={disputeSurchargeModalOpen}
                setOpen={setDisputeSurchargeModalOpen}
                chargeCodeData={allChargeCodeData}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                />
        </Space>
    )
}

export default SurchargeErrorTab;