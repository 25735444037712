import { Tag, Space, Col, Row, Button, Tooltip } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
export function FixedTrackingColumns(setOpenSurcharge, setModalContent) {
    return [
        {
            title: 'Tracking Number',
            dataIndex: ['rate_json', 'Tracking Number'],
            key: 'express_or_ground_tracking_id',
            fixed: 'left',
            render: (text, record) => {
                let fullTrk = '';
                if (!(record.rate_json['Grd Prefix'])) {
                    fullTrk = record.rate_json['Tracking Number']
                } else {
                    fullTrk = record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']
                }
                return <a
                    href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${fullTrk}&cntry_code=us&locale=en_us`}
                    target="_blank"
                >
                    {text}
                </a>
            }
        },
        {
            title: 'UID',
            dataIndex: 'UID',
            key: 'uid',
            width: 70,
            fixed: 'left',
        },
        {
            title: 'Surcharge Details',
            dataIndex: 'surcharge_json',
            key: 'surcharge',
            fixed: 'left',
            render: (text, record) => {
                return <Button type="link" onClick={() => {

                    const newModalContent = Object.entries(text).map(([key, value], index) => ({
                        key: index,
                        type: key,
                        amount: value
                    }));
                    setModalContent(newModalContent);
                    setOpenSurcharge(true);
                }}>
                    附加费详情
                </Button>
            }
        },
        {
            title: 'Full Tracking',
            dataIndex: 'full_tracking_number',
            key: 'full_tracking',
            render: (text, record) => {
                // if rate json Grd Prefix is null, just show the tracking number without prefix
                if (!(record.rate_json['Grd Prefix'])) {
                    return <span>{record.rate_json['Tracking Number']}</span>
                } else {
                    return <span>{record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']}</span>
                }
            },
            fixed: 'left',
        },
        {
            title: 'Master EDI Number',
            dataIndex: ['rate_json', 'Master EDI No']
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
        },
        {
            title: 'Total Invoice Charge',
            dataIndex: ['rate_json', 'Inv Charge'],
            key: 'Invoice Charge'
        },
        {
            title: 'Bill to Account Number',
            dataIndex: ['rate_json', 'Bill-to Account'],
            key: 'bill_to_account_number',
        },
        {
            title: 'Bill to country',
            dataIndex: ['rate_json', 'Cntry'],
            key: 'bill_to_country',
        },
        {
            title: 'Company Code',
            dataIndex: ['rate_json', 'Co.Cd'],
            key: 'company_code',
        },
        {
            title: 'Ground Tracking ID Prefix',
            dataIndex: ['rate_json', 'Grd Prefix'],
            key: 'ground_tracking_id_prefix',
        },
        {
            title: 'Shipment Date',
            dataIndex: ['rate_json', 'Ship Date'],
            key: 'shipment_date',
        },
        {
            title: 'Service',
            dataIndex: ['logistics_service', 'name'],
            key: 'service',
        },
        {
            title: 'Ground Service',
            dataIndex: ['rate_json', 'Grd Svc'],
            key: 'ground_service',
        },
        {
            title: 'Reference 1',
            dataIndex: ['rate_json', 'Ref 1'],
            key: 'ref_1',
        },
        {
            title: 'Reference 2',
            dataIndex: ['rate_json', 'Ref 2'],
            key: 'ref_2',
        },
        {
            title: 'Reference 3',
            dataIndex: ['rate_json', 'Ref 3'],
            key: 'ref_3',
        },
        {
            title: 'Customer Department Number',
            dataIndex: ['rate_json', 'Grd Dept No'],
            key: 'grd_dept_no',
        },
        {
            title: 'Net Charge',
            dataIndex: ['rate_json', 'Net Chrg'],
            key: 'net_charge',
        },
        {
            title: 'Currency',
            dataIndex: ['rate_json', 'Curr'],
            key: 'currency',
        },
        {
            title: 'Charge 1',
            dataIndex: ['rate_json', 'Chrg 1'],
            key: 'chrg_1',
        },
        {
            title: 'Freight Amount',
            dataIndex: ['rate_json', 'Freight Amt'],
            key: 'freight_amount',
        },
        {
            title: 'Scale',
            dataIndex: ['rate_json', 'Scale'],
            key: 'scale',
        },
        {
            title: 'Pieces',
            dataIndex: ['rate_json', 'Pcs'],
            key: 'Pcs',
        },
        {
            title: 'Bill Weight',
            dataIndex: ['rate_json', 'Bill Wt'],
            key: 'bill_weight',
        },
        {
            title: 'Original Weight',
            dataIndex: ['rate_json', 'Orig Wt'],
            key: 'original_weight',
        },
        {
            title: 'Multi-Weight',
            dataIndex: ['rate_json', 'Multi-Wt'],
            key: 'multi_weight',
        },
        {
            title: 'weight unit',
            dataIndex: ['rate_json', 'Wt Unit'],
            key: 'weight_unit',
        },
        {
            title: 'Length',
            dataIndex: ['rate_json', 'Length'],
            key: 'length',
        },
        {
            title: 'Width',
            dataIndex: ['rate_json', 'Width'],
            key: 'width',
        },
        {
            title: 'Height',
            dataIndex: ['rate_json', 'Height'],
            key: 'height',
        },
        {
            title: 'Dimension Unit',
            dataIndex: ['rate_json', 'Dim Unit'],
            key: 'dimension_unit',
        },
        {
            title: 'Divisor',
            dataIndex: ['rate_json', 'Divisor'],
            key: 'divisor',
        },
        {
            title: 'Ground Miscellaneous Description 1',
            dataIndex: ['rate_json', 'Grd Misc 1'],
            key: 'grd_misc_desc_1',
        },
        {
            title: 'Ground Miscellaneous Description 2',
            dataIndex: ['rate_json', 'Grd Misc 2'],
            key: 'grd_misc_desc_2',
        },
        {
            title: 'Ground Miscellaneous Description 3',
            dataIndex: ['rate_json', 'Grd Misc 3'],
            key: 'grd_misc_desc_3',
        },
        {
            title: 'Shipper Name',
            dataIndex: ['rate_json', 'Shipper Name'],
            key: 'shipper_name',
        },
        {
            title: 'Shipper Company',
            dataIndex: ['rate_json', 'Shipper Company'],
            key: 'shipper_company',
        },
        {
            title: 'Shipper Dept',
            dataIndex: ['rate_json', 'Shipper Dept'],
            key: 'shipper_dept',
        },
        {
            title: 'Shipper Address 1',
            dataIndex: ['rate_json', 'Shipper Address 1'],
            key: 'shipper_addr1',
        },
        {
            title: 'Shipper Address 2',
            dataIndex: ['rate_json', 'Shipper Address 2'],
            key: 'shipper_addr2',
        },
        {
            title: 'Shipper City',
            dataIndex: ['rate_json', 'Shipper City'],
            key: 'shipper_city',
        },
        {
            title: 'ST',
            dataIndex: ['rate_json', 'ST'],
            key: 'shipper_st',
        },
        {
            title: 'Postal',
            dataIndex: ['rate_json', 'Postal'],
            key: 'postal',
        },
        {
            title: 'US Origin',
            dataIndex: ['rate_json', 'US Origin'],
            key: 'us_origin',
        },
        {
            title: 'Cntry1',
            dataIndex: ['rate_json', 'Cntry1'],
            key: 'cntry1',
        },
        {
            title: 'Region',
            dataIndex: ['rate_json', 'Region'],
            key: 'region',
        },
        {
            title: 'Recipient Name',
            dataIndex: ['rate_json', 'Recipient Name'],
            key: 'recipient_name',
        },
        {
            title: 'Recipient Company',
            dataIndex: ['rate_json', 'Recipient Company'],
            key: 'recipient_company',
        },
        {
            title: 'Recipient Address 1',
            dataIndex: ['rate_json', 'Recipient Address 1'],
            key: 'recipient_addr1',
        },
        {
            title: 'Recipient Address 2',
            dataIndex: ['rate_json', 'Recipient Address 2'],
            key: 'recipient_addr2',
        },
        {
            title: 'Recipient City',
            dataIndex: ['rate_json', 'Recipient City'],
            key: 'recipient_city',
        },
        {
            title: 'ST2',
            dataIndex: ['rate_json', 'ST2'],
            key: 'recipient_st',
        },
        {
            title: 'Postal2',
            dataIndex: ['rate_json', 'Postal2'],
            key: 'recipient_postal',
        },
        {
            title: 'Cntry2',
            dataIndex: ['rate_json', 'Cntry2'],
            key: 'recipient_country',
        },
        {
            title: 'Hndlg',
            dataIndex: ['rate_json', 'Hndlg'],
            key: 'hndlg',
        },
        {
            title: 'Dlvry Date',
            dataIndex: ['rate_json', 'Dlvry Date'],
            key: 'dlvry_date',
        },
        {
            title: 'Time',
            dataIndex: ['rate_json', 'Time'],
            key: 'time',
        },
        {
            title: 'Final',
            dataIndex: ['rate_json', 'Final'],
            key: 'final',
        },
        {
            title: 'Exceptn',
            dataIndex: ['rate_json', 'Exceptn'],
            key: 'exceptn',
        },
        {
            title: 'Attempt Date',
            dataIndex: ['rate_json', 'Attempt Date'],
            key: 'attempt_date',
        },
        {
            title: 'Attempt Time',
            dataIndex: ['rate_json', 'Attempt Time'],
            key: 'attempt_time',
        },
        {
            title: 'Signature',
            dataIndex: ['rate_json', 'Signature'],
            key: 'signature',
        },
        {
            title: 'Svc Area',
            dataIndex: ['rate_json', 'Svc Area'],
            key: 'svc_area',
        },
        {
            title: 'Orig Recip Adr 1',
            dataIndex: ['rate_json', 'Orig Recip Adr 1'],
            key: 'orig_recip_adr_1',
        },
        {
            title: 'Orig Recip Adr 2',
            dataIndex: ['rate_json', 'Orig Recip Adr 2'],
            key: 'orig_recip_adr_2',
        },
        {
            title: 'Original City',
            dataIndex: ['rate_json', 'Original City'],
            key: 'orig_city',
        },
        {
            title: '计算错误',
            dataIndex: 'calculation_error',
            key: 'calculation_error',
        }
    ]
}
export function UserDefinedExceptionsFixedTrackingColumns(setOpenSurcharge, setModalContent) {
    return [
        {
            title: 'Tracking Number',
            dataIndex: ['rate_json', 'Tracking Number'],
            key: 'express_or_ground_tracking_id',
            fixed: 'left',
            render: (text, record) => {
                let fullTrk = '';
                if (!(record.rate_json['Grd Prefix'])) {
                    fullTrk = record.rate_json['Tracking Number']
                } else {
                    fullTrk = record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']
                }
                return <a
                    href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${fullTrk}&cntry_code=us&locale=en_us`}
                    target="_blank"
                >
                    {text}
                </a>
            }
        },
        {
            title: 'UID',
            dataIndex: 'UID',
            key: 'uid',
            width: 70,
            fixed: 'left',
        },
        {
            title: 'Surcharge Details',
            dataIndex: 'surcharge_json',
            key: 'surcharge',
            fixed: 'left',
            render: (text, record) => {
                return <Button type="link" onClick={() => {

                    const newModalContent = Object.entries(text).map(([key, value], index) => ({
                        key: index,
                        type: key,
                        amount: value
                    }));
                    setModalContent(newModalContent);
                    setOpenSurcharge(true);
                }}>
                    附加费详情
                </Button>
            }
        },
        {
            title: 'Full Tracking',
            dataIndex: 'full_tracking_number',
            key: 'full_tracking',
            render: (text, record) => {
                // if rate json Grd Prefix is null, just show the tracking number without prefix
                if (!(record.rate_json['Grd Prefix'])) {
                    return <span>{record.rate_json['Tracking Number']}</span>
                } else {
                    return <span>{record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']}</span>
                }
            },
            fixed: 'left',
        },
        {
            title: '异常详情',
            dataIndex: 'user_defined_exceptions',
            key: 'user_defined_exceptions',
            fixed: 'left',
            render: (text, record) => {
                // record.user_defined_exceptions is a list, each element is a string
                // render as tags
                return <Space>
                    {record.user_defined_exceptions.map((item, index) => {
                        return <Tag key={index}>{item}</Tag>
                    })}
                </Space>
            }
        },
        {
            title: 'Master EDI Number',
            dataIndex: ['rate_json', 'Master EDI No']
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
        },
        {
            title: 'Total Invoice Charge',
            dataIndex: ['rate_json', 'Inv Charge'],
            key: 'Invoice Charge'
        },
        {
            title: 'Bill to Account Number',
            dataIndex: ['rate_json', 'Bill-to Account'],
            key: 'bill_to_account_number',
        },
        {
            title: 'Bill to country',
            dataIndex: ['rate_json', 'Cntry'],
            key: 'bill_to_country',
        },
        {
            title: 'Company Code',
            dataIndex: ['rate_json', 'Co.Cd'],
            key: 'company_code',
        },
        {
            title: 'Ground Tracking ID Prefix',
            dataIndex: ['rate_json', 'Grd Prefix'],
            key: 'ground_tracking_id_prefix',
        },
        {
            title: 'Shipment Date',
            dataIndex: ['rate_json', 'Ship Date'],
            key: 'shipment_date',
        },
        {
            title: 'Service',
            dataIndex: ['logistics_service', 'name'],
            key: 'service',
        },
        {
            title: 'Ground Service',
            dataIndex: ['rate_json', 'Grd Svc'],
            key: 'ground_service',
        },
        {
            title: 'Reference 1',
            dataIndex: ['rate_json', 'Ref 1'],
            key: 'ref_1',
        },
        {
            title: 'Reference 2',
            dataIndex: ['rate_json', 'Ref 2'],
            key: 'ref_2',
        },
        {
            title: 'Reference 3',
            dataIndex: ['rate_json', 'Ref 3'],
            key: 'ref_3',
        },
        {
            title: 'Customer Department Number',
            dataIndex: ['rate_json', 'Grd Dept No'],
            key: 'grd_dept_no',
        },
        {
            title: 'Net Charge',
            dataIndex: ['rate_json', 'Net Chrg'],
            key: 'net_charge',
        },
        {
            title: 'Currency',
            dataIndex: ['rate_json', 'Curr'],
            key: 'currency',
        },
        {
            title: 'Charge 1',
            dataIndex: ['rate_json', 'Chrg 1'],
            key: 'chrg_1',
        },
        {
            title: 'Freight Amount',
            dataIndex: ['rate_json', 'Freight Amt'],
            key: 'freight_amount',
        },
        {
            title: 'Scale',
            dataIndex: ['rate_json', 'Scale'],
            key: 'scale',
        },
        {
            title: 'Pieces',
            dataIndex: ['rate_json', 'Pcs'],
            key: 'Pcs',
        },
        {
            title: 'Bill Weight',
            dataIndex: ['rate_json', 'Bill Wt'],
            key: 'bill_weight',
        },
        {
            title: 'Original Weight',
            dataIndex: ['rate_json', 'Orig Wt'],
            key: 'original_weight',
        },
        {
            title: 'Multi-Weight',
            dataIndex: ['rate_json', 'Multi-Wt'],
            key: 'multi_weight',
        },
        {
            title: 'weight unit',
            dataIndex: ['rate_json', 'Wt Unit'],
            key: 'weight_unit',
        },
        {
            title: 'Length',
            dataIndex: ['rate_json', 'Length'],
            key: 'length',
        },
        {
            title: 'Width',
            dataIndex: ['rate_json', 'Width'],
            key: 'width',
        },
        {
            title: 'Height',
            dataIndex: ['rate_json', 'Height'],
            key: 'height',
        },
        {
            title: 'Dimension Unit',
            dataIndex: ['rate_json', 'Dim Unit'],
            key: 'dimension_unit',
        },
        {
            title: 'Divisor',
            dataIndex: ['rate_json', 'Divisor'],
            key: 'divisor',
        },
        {
            title: 'Ground Miscellaneous Description 1',
            dataIndex: ['rate_json', 'Grd Misc 1'],
            key: 'grd_misc_desc_1',
        },
        {
            title: 'Ground Miscellaneous Description 2',
            dataIndex: ['rate_json', 'Grd Misc 2'],
            key: 'grd_misc_desc_2',
        },
        {
            title: 'Ground Miscellaneous Description 3',
            dataIndex: ['rate_json', 'Grd Misc 3'],
            key: 'grd_misc_desc_3',
        },
        {
            title: 'Shipper Name',
            dataIndex: ['rate_json', 'Shipper Name'],
            key: 'shipper_name',
        },
        {
            title: 'Shipper Company',
            dataIndex: ['rate_json', 'Shipper Company'],
            key: 'shipper_company',
        },
        {
            title: 'Shipper Dept',
            dataIndex: ['rate_json', 'Shipper Dept'],
            key: 'shipper_dept',
        },
        {
            title: 'Shipper Address 1',
            dataIndex: ['rate_json', 'Shipper Address 1'],
            key: 'shipper_addr1',
        },
        {
            title: 'Shipper Address 2',
            dataIndex: ['rate_json', 'Shipper Address 2'],
            key: 'shipper_addr2',
        },
        {
            title: 'Shipper City',
            dataIndex: ['rate_json', 'Shipper City'],
            key: 'shipper_city',
        },
        {
            title: 'ST',
            dataIndex: ['rate_json', 'ST'],
            key: 'shipper_st',
        },
        {
            title: 'Postal',
            dataIndex: ['rate_json', 'Postal'],
            key: 'postal',
        },
        {
            title: 'US Origin',
            dataIndex: ['rate_json', 'US Origin'],
            key: 'us_origin',
        },
        {
            title: 'Cntry1',
            dataIndex: ['rate_json', 'Cntry1'],
            key: 'cntry1',
        },
        {
            title: 'Region',
            dataIndex: ['rate_json', 'Region'],
            key: 'region',
        },
        {
            title: 'Recipient Name',
            dataIndex: ['rate_json', 'Recipient Name'],
            key: 'recipient_name',
        },
        {
            title: 'Recipient Company',
            dataIndex: ['rate_json', 'Recipient Company'],
            key: 'recipient_company',
        },
        {
            title: 'Recipient Address 1',
            dataIndex: ['rate_json', 'Recipient Address 1'],
            key: 'recipient_addr1',
        },
        {
            title: 'Recipient Address 2',
            dataIndex: ['rate_json', 'Recipient Address 2'],
            key: 'recipient_addr2',
        },
        {
            title: 'Recipient City',
            dataIndex: ['rate_json', 'Recipient City'],
            key: 'recipient_city',
        },
        {
            title: 'ST2',
            dataIndex: ['rate_json', 'ST2'],
            key: 'recipient_st',
        },
        {
            title: 'Postal2',
            dataIndex: ['rate_json', 'Postal2'],
            key: 'recipient_postal',
        },
        {
            title: 'Cntry2',
            dataIndex: ['rate_json', 'Cntry2'],
            key: 'recipient_country',
        },
        {
            title: 'Hndlg',
            dataIndex: ['rate_json', 'Hndlg'],
            key: 'hndlg',
        },
        {
            title: 'Dlvry Date',
            dataIndex: ['rate_json', 'Dlvry Date'],
            key: 'dlvry_date',
        },
        {
            title: 'Time',
            dataIndex: ['rate_json', 'Time'],
            key: 'time',
        },
        {
            title: 'Final',
            dataIndex: ['rate_json', 'Final'],
            key: 'final',
        },
        {
            title: 'Exceptn',
            dataIndex: ['rate_json', 'Exceptn'],
            key: 'exceptn',
        },
        {
            title: 'Attempt Date',
            dataIndex: ['rate_json', 'Attempt Date'],
            key: 'attempt_date',
        },
        {
            title: 'Attempt Time',
            dataIndex: ['rate_json', 'Attempt Time'],
            key: 'attempt_time',
        },
        {
            title: 'Signature',
            dataIndex: ['rate_json', 'Signature'],
            key: 'signature',
        },
        {
            title: 'Svc Area',
            dataIndex: ['rate_json', 'Svc Area'],
            key: 'svc_area',
        },
        {
            title: 'Orig Recip Adr 1',
            dataIndex: ['rate_json', 'Orig Recip Adr 1'],
            key: 'orig_recip_adr_1',
        },
        {
            title: 'Orig Recip Adr 2',
            dataIndex: ['rate_json', 'Orig Recip Adr 2'],
            key: 'orig_recip_adr_2',
        },
        {
            title: 'Original City',
            dataIndex: ['rate_json', 'Original City'],
            key: 'orig_city',
        },
        {
            title: '计算错误',
            dataIndex: 'calculation_error',
            key: 'calculation_error',
        }
    ]
}


export function FixedTrackingMasterTrackingMismatchColumns(setOpenSurcharge, setModalContent) {
    return [
        {
            title: 'Tracking Number',
            dataIndex: ['rate_json', 'Tracking Number'],
            key: 'express_or_ground_tracking_id',
            fixed: 'left',
            render: (text, record) => {
                let fullTrk = '';
                if (!(record.rate_json['Grd Prefix'])) {
                    fullTrk = record.rate_json['Tracking Number']
                } else {
                    fullTrk = record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']
                }
                return <a
                    href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${fullTrk}&cntry_code=us&locale=en_us`}
                    target="_blank"
                >
                    {text}
                </a>
            }
        },
        {
            title: 'UID',
            dataIndex: ['customer', 'uid'],
            key: 'uid',
            width: 70,
            fixed: 'left',
        },
        {
            title: 'Surcharge Details',
            dataIndex: 'surcharge_json',
            key: 'surcharge',
            fixed: 'left',
            render: (text, record) => {
                return <Button type="link" onClick={() => {

                    const newModalContent = Object.entries(text).map(([key, value], index) => ({
                        key: index,
                        type: key,
                        amount: value
                    }));
                    setModalContent(newModalContent);
                    setOpenSurcharge(true);
                }}>
                    附加费详情
                </Button>
            }
        },
        {
            title: 'unmatch_status',
            dataIndex: 'unmatch_status',
            key: 'unmatch_status',
            align: "center",
            fixed: 'left',
            width: 200,
            render: (text, record) => {
                let unmatch_tags = [];
                let keySuffix = 0;  // 用于确保key的唯一性
                if (record.service_match === 1) {
                    unmatch_tags.push(<Col key={`service_match-${record.id}-${record.service_match}`}><Tag>Service异常</Tag></Col>)
                } 
                if (record.valid_shipment_not_found) {
                    unmatch_tags.push(<Col key={`valid_shipment_not_found-${record.id}-${record.valid_shipment_not_found}`}><Tag>系统内部匹配失败</Tag></Col>)
                } 
                if (record.service_match === 0 && !record.valid_shipment_not_found) {
                    unmatch_tags.push(<Col key={`service_match-${record.id}-${record.service_match}`}><Tag>Master未匹配</Tag></Col>)
                }
                return <Row gutter={16} style={{ flexWrap: 'wrap' }}>{unmatch_tags}</Row>;
            }
        },
        {
            title: 'Full Tracking',
            dataIndex: 'full_tracking_number',
            key: 'full_tracking',
            render: (text, record) => {
                // if rate json Grd Prefix is null, just show the tracking number without prefix
                if (!(record.rate_json['Grd Prefix'])) {
                    return <span>{record.rate_json['Tracking Number']}</span>
                } else {
                    return <span>{record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']}</span>
                }
            },
            fixed: 'left',
        },
        {
            title: 'Master EDI Number',
            dataIndex: ['rate_json', 'Master EDI No']
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
        },
        {
            title: 'Total Invoice Charge',
            dataIndex: ['rate_json', 'Inv Charge'],
            key: 'Invoice Charge'
        },
        {
            title: 'Bill to Account Number',
            dataIndex: ['rate_json', 'Bill-to Account'],
            key: 'bill_to_account_number',
        },
        {
            title: 'Bill to country',
            dataIndex: ['rate_json', 'Cntry'],
            key: 'bill_to_country',
        },
        {
            title: 'Company Code',
            dataIndex: ['rate_json', 'Co.Cd'],
            key: 'company_code',
        },
        {
            title: 'Ground Tracking ID Prefix',
            dataIndex: ['rate_json', 'Grd Prefix'],
            key: 'ground_tracking_id_prefix',
        },
        {
            title: 'Shipment Date',
            dataIndex: ['rate_json', 'Ship Date'],
            key: 'shipment_date',
        },
        {
            title: 'Service',
            dataIndex: ['logistics_service', 'name'],
            key: 'service',
        },
        {
            title: 'Ground Service',
            dataIndex: ['rate_json', 'Grd Svc'],
            key: 'ground_service',
        },
        {
            title: 'Reference 1',
            dataIndex: ['rate_json', 'Ref 1'],
            key: 'ref_1',
        },
        {
            title: 'Reference 2',
            dataIndex: ['rate_json', 'Ref 2'],
            key: 'ref_2',
        },
        {
            title: 'Reference 3',
            dataIndex: ['rate_json', 'Ref 3'],
            key: 'ref_3',
        },
        {
            title: 'Customer Department Number',
            dataIndex: ['rate_json', 'Grd Dept No'],
            key: 'grd_dept_no',
        },
        {
            title: 'Net Charge',
            dataIndex: ['rate_json', 'Net Chrg'],
            key: 'net_charge',
        },
        {
            title: 'Currency',
            dataIndex: ['rate_json', 'Curr'],
            key: 'currency',
        },
        {
            title: 'Charge 1',
            dataIndex: ['rate_json', 'Chrg 1'],
            key: 'chrg_1',
        },
        {
            title: 'Freight Amount',
            dataIndex: ['rate_json', 'Freight Amt'],
            key: 'freight_amount',
        },
        {
            title: 'Scale',
            dataIndex: ['rate_json', 'Scale'],
            key: 'scale',
        },
        {
            title: 'Pieces',
            dataIndex: ['rate_json', 'Pcs'],
            key: 'Pcs',
        },
        {
            title: 'Bill Weight',
            dataIndex: ['rate_json', 'Bill Wt'],
            key: 'bill_weight',
        },
        {
            title: 'Original Weight',
            dataIndex: ['rate_json', 'Orig Wt'],
            key: 'original_weight',
        },
        {
            title: 'Multi-Weight',
            dataIndex: ['rate_json', 'Multi-Wt'],
            key: 'multi_weight',
        },
        {
            title: 'weight unit',
            dataIndex: ['rate_json', 'Wt Unit'],
            key: 'weight_unit',
        },
        {
            title: 'Length',
            dataIndex: ['rate_json', 'Length'],
            key: 'length',
        },
        {
            title: 'Width',
            dataIndex: ['rate_json', 'Width'],
            key: 'width',
        },
        {
            title: 'Height',
            dataIndex: ['rate_json', 'Height'],
            key: 'height',
        },
        {
            title: 'Dimension Unit',
            dataIndex: ['rate_json', 'Dim Unit'],
            key: 'dimension_unit',
        },
        {
            title: 'Divisor',
            dataIndex: ['rate_json', 'Divisor'],
            key: 'divisor',
        },
        {
            title: 'Ground Miscellaneous Description 1',
            dataIndex: ['rate_json', 'Grd Misc 1'],
            key: 'grd_misc_desc_1',
        },
        {
            title: 'Ground Miscellaneous Description 2',
            dataIndex: ['rate_json', 'Grd Misc 2'],
            key: 'grd_misc_desc_2',
        },
        {
            title: 'Ground Miscellaneous Description 3',
            dataIndex: ['rate_json', 'Grd Misc 3'],
            key: 'grd_misc_desc_3',
        },
        {
            title: 'Shipper Name',
            dataIndex: ['rate_json', 'Shipper Name'],
            key: 'shipper_name',
        },
        {
            title: 'Shipper Company',
            dataIndex: ['rate_json', 'Shipper Company'],
            key: 'shipper_company',
        },
        {
            title: 'Shipper Dept',
            dataIndex: ['rate_json', 'Shipper Dept'],
            key: 'shipper_dept',
        },
        {
            title: 'Shipper Address 1',
            dataIndex: ['rate_json', 'Shipper Address 1'],
            key: 'shipper_addr1',
        },
        {
            title: 'Shipper Address 2',
            dataIndex: ['rate_json', 'Shipper Address 2'],
            key: 'shipper_addr2',
        },
        {
            title: 'Shipper City',
            dataIndex: ['rate_json', 'Shipper City'],
            key: 'shipper_city',
        },
        {
            title: 'ST',
            dataIndex: ['rate_json', 'ST'],
            key: 'shipper_st',
        },
        {
            title: 'Postal',
            dataIndex: ['rate_json', 'Postal'],
            key: 'postal',
        },
        {
            title: 'US Origin',
            dataIndex: ['rate_json', 'US Origin'],
            key: 'us_origin',
        },
        {
            title: 'Cntry1',
            dataIndex: ['rate_json', 'Cntry1'],
            key: 'cntry1',
        },
        {
            title: 'Region',
            dataIndex: ['rate_json', 'Region'],
            key: 'region',
        },
        {
            title: 'Recipient Name',
            dataIndex: ['rate_json', 'Recipient Name'],
            key: 'recipient_name',
        },
        {
            title: 'Recipient Company',
            dataIndex: ['rate_json', 'Recipient Company'],
            key: 'recipient_company',
        },
        {
            title: 'Recipient Address 1',
            dataIndex: ['rate_json', 'Recipient Address 1'],
            key: 'recipient_addr1',
        },
        {
            title: 'Recipient Address 2',
            dataIndex: ['rate_json', 'Recipient Address 2'],
            key: 'recipient_addr2',
        },
        {
            title: 'Recipient City',
            dataIndex: ['rate_json', 'Recipient City'],
            key: 'recipient_city',
        },
        {
            title: 'ST2',
            dataIndex: ['rate_json', 'ST2'],
            key: 'recipient_st',
        },
        {
            title: 'Postal2',
            dataIndex: ['rate_json', 'Postal2'],
            key: 'recipient_postal',
        },
        {
            title: 'Cntry2',
            dataIndex: ['rate_json', 'Cntry2'],
            key: 'recipient_country',
        },
        {
            title: 'Hndlg',
            dataIndex: ['rate_json', 'Hndlg'],
            key: 'hndlg',
        },
        {
            title: 'Dlvry Date',
            dataIndex: ['rate_json', 'Dlvry Date'],
            key: 'dlvry_date',
        },
        {
            title: 'Time',
            dataIndex: ['rate_json', 'Time'],
            key: 'time',
        },
        {
            title: 'Final',
            dataIndex: ['rate_json', 'Final'],
            key: 'final',
        },
        {
            title: 'Exceptn',
            dataIndex: ['rate_json', 'Exceptn'],
            key: 'exceptn',
        },
        {
            title: 'Attempt Date',
            dataIndex: ['rate_json', 'Attempt Date'],
            key: 'attempt_date',
        },
        {
            title: 'Attempt Time',
            dataIndex: ['rate_json', 'Attempt Time'],
            key: 'attempt_time',
        },
        {
            title: 'Signature',
            dataIndex: ['rate_json', 'Signature'],
            key: 'signature',
        },
        {
            title: 'Svc Area',
            dataIndex: ['rate_json', 'Svc Area'],
            key: 'svc_area',
        },
        {
            title: 'Orig Recip Adr 1',
            dataIndex: ['rate_json', 'Orig Recip Adr 1'],
            key: 'orig_recip_adr_1',
        },
        {
            title: 'Orig Recip Adr 2',
            dataIndex: ['rate_json', 'Orig Recip Adr 2'],
            key: 'orig_recip_adr_2',
        },
        {
            title: 'Original City',
            dataIndex: ['rate_json', 'Original City'],
            key: 'orig_city',
        },
        {
            title: '计算错误',
            dataIndex: 'calculation_error',
            key: 'calculation_error',
        }
    ]
}

export function FixedTrackingAndSurchargeColumns(setOpenSurcharge, setModalContent) {
    return [
        {
            title: 'Tracking Number',
            dataIndex: ['rate_json', 'Tracking Number'],
            key: 'express_or_ground_tracking_id',
            fixed: 'left',
            render: (text, record) => {
                let fullTrk = '';
                if (!(record.rate_json['Grd Prefix'])) {
                    fullTrk = record.rate_json['Tracking Number']
                } else {
                    fullTrk = record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']
                }
                return <a
                    href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${fullTrk}&cntry_code=us&locale=en_us`}
                    target="_blank"
                >
                    {text}
                </a>
            }
        },
        {
            title: 'Unrecognized Surcharges',
            dataIndex: 'surcharge_error_json',
            key: 'unrecognized_surcharges',
            render: (surcharge_error_json) => {
                // get the keys of the surcharge_error_json
                const keys = Object.keys(surcharge_error_json);
                return keys.join(', ');
            },
            fixed: 'left',
        },
        {
            title: 'UID',
            dataIndex: 'UID',
            key: 'uid',
            width: 70,
            fixed: 'left',
        },
        {
            title: 'Full Tracking',
            dataIndex: 'full_tracking_number',
            key: 'full_tracking',
            render: (text, record) => {
                // if rate json Grd Prefix is null, just show the tracking number without prefix
                if (!(record.rate_json['Grd Prefix'])) {
                    return <span>{record.rate_json['Tracking Number']}</span>
                } else {
                    return <span>{record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']}</span>
                }
            },
            fixed: 'left',
        },
        {
            title: 'Surcharge Details',
            dataIndex: 'surcharge_json',
            key: 'surcharge',
            fixed: 'left',
            render: (text, record) => {
                return <Button type="link" onClick={() => {

                    const newModalContent = Object.entries(text).map(([key, value], index) => ({
                        key: index,
                        type: key,
                        amount: value
                    }));
                    setModalContent(newModalContent);
                    setOpenSurcharge(true);
                }}>
                    附加费详情
                </Button>
            }
        },
        {
            title: 'Total of all `Other Charge` Components价格',
            dataIndex: 'zzz_price',
            key: 'zzz_price',
        },
        {
            title: 'Master EDI Number',
            dataIndex: ['rate_json', 'Master EDI No']
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
        },
        {
            title: 'Total Invoice Charge',
            dataIndex: ['rate_json', 'Inv Charge'],
            key: 'Invoice Charge'
        },
        {
            title: 'Bill to Account Number',
            dataIndex: ['rate_json', 'Bill-to Account'],
            key: 'bill_to_account_number',
        },
        {
            title: 'Bill to country',
            dataIndex: ['rate_json', 'Cntry'],
            key: 'bill_to_country',
        },
        {
            title: 'Company Code',
            dataIndex: ['rate_json', 'Co.Cd'],
            key: 'company_code',
        },
        {
            title: 'Ground Tracking ID Prefix',
            dataIndex: ['rate_json', 'Grd Prefix'],
            key: 'ground_tracking_id_prefix',
        },
        {
            title: 'Shipment Date',
            dataIndex: ['rate_json', 'Ship Date'],
            key: 'shipment_date',
        },
        {
            title: 'Service',
            dataIndex: ['rate_json', 'Svc'],
            key: 'service',
        },
        {
            title: 'Ground Service',
            dataIndex: ['rate_json', 'Grd Svc'],
            key: 'ground_service',
        },
        {
            title: 'Reference 1',
            dataIndex: ['rate_json', 'Ref 1'],
            key: 'ref_1',
        },
        {
            title: 'Reference 2',
            dataIndex: ['rate_json', 'Ref 2'],
            key: 'ref_2',
        },
        {
            title: 'Reference 3',
            dataIndex: ['rate_json', 'Ref 3'],
            key: 'ref_3',
        },
        {
            title: 'Customer Department Number',
            dataIndex: ['rate_json', 'Grd Dept No'],
            key: 'grd_dept_no',
        },
        {
            title: 'Net Charge',
            dataIndex: ['rate_json', 'Net Chrg'],
            key: 'net_charge',
        },
        {
            title: 'Currency',
            dataIndex: ['rate_json', 'Curr'],
            key: 'currency',
        },
        {
            title: 'Charge 1',
            dataIndex: ['rate_json', 'Chrg 1'],
            key: 'chrg_1',
        },
        {
            title: 'Freight Amount',
            dataIndex: ['rate_json', 'Freight Amt'],
            key: 'freight_amount',
        },
        {
            title: 'Scale',
            dataIndex: ['rate_json', 'Scale'],
            key: 'scale',
        },
        {
            title: 'Pieces',
            dataIndex: ['rate_json', 'Pcs'],
            key: 'Pcs',
        },
        {
            title: 'Bill Weight',
            dataIndex: ['rate_json', 'Bill Wt'],
            key: 'bill_weight',
        },
        {
            title: 'Original Weight',
            dataIndex: ['rate_json', 'Orig Wt'],
            key: 'original_weight',
        },
        {
            title: 'Multi-Weight',
            dataIndex: ['rate_json', 'Multi-Wt'],
            key: 'multi_weight',
        },
        {
            title: 'weight unit',
            dataIndex: ['rate_json', 'Wt Unit'],
            key: 'weight_unit',
        },
        {
            title: 'Length',
            dataIndex: ['rate_json', 'Length'],
            key: 'length',
        },
        {
            title: 'Width',
            dataIndex: ['rate_json', 'Width'],
            key: 'width',
        },
        {
            title: 'Height',
            dataIndex: ['rate_json', 'Height'],
            key: 'height',
        },
        {
            title: 'Dimension Unit',
            dataIndex: ['rate_json', 'Dim Unit'],
            key: 'dimension_unit',
        },
        {
            title: 'Divisor',
            dataIndex: ['rate_json', 'Divisor'],
            key: 'divisor',
        },
        {
            title: 'Ground Miscellaneous Description 1',
            dataIndex: ['rate_json', 'Grd Misc 1'],
            key: 'grd_misc_desc_1',
        },
        {
            title: 'Ground Miscellaneous Description 2',
            dataIndex: ['rate_json', 'Grd Misc 2'],
            key: 'grd_misc_desc_2',
        },
        {
            title: 'Ground Miscellaneous Description 3',
            dataIndex: ['rate_json', 'Grd Misc 3'],
            key: 'grd_misc_desc_3',
        },
        {
            title: 'Shipper Name',
            dataIndex: ['rate_json', 'Shipper Name'],
            key: 'shipper_name',
        },
        {
            title: 'Shipper Company',
            dataIndex: ['rate_json', 'Shipper Company'],
            key: 'shipper_company',
        },
        {
            title: 'Shipper Dept',
            dataIndex: ['rate_json', 'Shipper Dept'],
            key: 'shipper_dept',
        },
        {
            title: 'Shipper Address 1',
            dataIndex: ['rate_json', 'Shipper Address 1'],
            key: 'shipper_addr1',
        },
        {
            title: 'Shipper Address 2',
            dataIndex: ['rate_json', 'Shipper Address 2'],
            key: 'shipper_addr2',
        },
        {
            title: 'Shipper City',
            dataIndex: ['rate_json', 'Shipper City'],
            key: 'shipper_city',
        },
        {
            title: 'ST',
            dataIndex: ['rate_json', 'ST'],
            key: 'shipper_st',
        },
        {
            title: 'Postal',
            dataIndex: ['rate_json', 'Postal'],
            key: 'postal',
        },
        {
            title: 'US Origin',
            dataIndex: ['rate_json', 'US Origin'],
            key: 'us_origin',
        },
        {
            title: 'Cntry1',
            dataIndex: ['rate_json', 'Cntry1'],
            key: 'cntry1',
        },
        {
            title: 'Region',
            dataIndex: ['rate_json', 'Region'],
            key: 'region',
        },
        {
            title: 'Recipient Name',
            dataIndex: ['rate_json', 'Recipient Name'],
            key: 'recipient_name',
        },
        {
            title: 'Recipient Company',
            dataIndex: ['rate_json', 'Recipient Company'],
            key: 'recipient_company',
        },
        {
            title: 'Recipient Address 1',
            dataIndex: ['rate_json', 'Recipient Address 1'],
            key: 'recipient_addr1',
        },
        {
            title: 'Recipient Address 2',
            dataIndex: ['rate_json', 'Recipient Address 2'],
            key: 'recipient_addr2',
        },
        {
            title: 'Recipient City',
            dataIndex: ['rate_json', 'Recipient City'],
            key: 'recipient_city',
        },
        {
            title: 'ST2',
            dataIndex: ['rate_json', 'ST2'],
            key: 'recipient_st',
        },
        {
            title: 'Postal2',
            dataIndex: ['rate_json', 'Postal2'],
            key: 'recipient_postal',
        },
        {
            title: 'Cntry2',
            dataIndex: ['rate_json', 'Cntry2'],
            key: 'recipient_country',
        },
        {
            title: 'Hndlg',
            dataIndex: ['rate_json', 'Hndlg'],
            key: 'hndlg',
        },
        {
            title: 'Dlvry Date',
            dataIndex: ['rate_json', 'Dlvry Date'],
            key: 'dlvry_date',
        },
        {
            title: 'Time',
            dataIndex: ['rate_json', 'Time'],
            key: 'time',
        },
        {
            title: 'Final',
            dataIndex: ['rate_json', 'Final'],
            key: 'final',
        },
        {
            title: 'Exceptn',
            dataIndex: ['rate_json', 'Exceptn'],
            key: 'exceptn',
        },
        {
            title: 'Attempt Date',
            dataIndex: ['rate_json', 'Attempt Date'],
            key: 'attempt_date',
        },
        {
            title: 'Attempt Time',
            dataIndex: ['rate_json', 'Attempt Time'],
            key: 'attempt_time',
        },
        {
            title: 'Signature',
            dataIndex: ['rate_json', 'Signature'],
            key: 'signature',
        },
        {
            title: 'Svc Area',
            dataIndex: ['rate_json', 'Svc Area'],
            key: 'svc_area',
        },
        {
            title: 'Orig Recip Adr 1',
            dataIndex: ['rate_json', 'Orig Recip Adr 1'],
            key: 'orig_recip_adr_1',
        },
        {
            title: 'Orig Recip Adr 2',
            dataIndex: ['rate_json', 'Orig Recip Adr 2'],
            key: 'orig_recip_adr_2',
        },
        {
            title: 'Original City',
            dataIndex: ['rate_json', 'Original City'],
            key: 'orig_city',
        },
        {
            title: '计算错误',
            dataIndex: 'calculation_error',
            key: 'calculation_error',
        }
    ]
}


export function FixedTrackingDupTrackingColumns(setOpenSurcharge, setModalContent, dupDetailsButtonHandler) {
    return [
        {
            title: 'Tracking Number',
            dataIndex: ['rate_json', 'Tracking Number'],
            key: 'express_or_ground_tracking_id',
            fixed: 'left',
            render: (text, record) => {
                let fullTrk = '';
                if (!(record.rate_json['Grd Prefix'])) {
                    fullTrk = record.rate_json['Tracking Number']
                } else {
                    fullTrk = record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']
                }
                return <a
                    href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${fullTrk}&cntry_code=us&locale=en_us`}
                    target="_blank"
                >
                    {text}
                </a>
            }
        },
        {
            title: 'dup status',
            dataIndex: 'dup_status',
            key: 'dup_status',
            align: "center",
            fixed: 'left',
            width: 200,
            render: (text, record) => {
                let return_tags = [];
                let keySuffix = 0;  // 用于确保key的唯一性
                if (record.dup === 1) {
                    return_tags.push(<Col key={`dup_current-${record.id}-${keySuffix++}`}><Tag>当期重复</Tag></Col>)
                }
                if (record.dup === 2) {
                    return_tags.push(<Col key={`dup_history-${record.id}-${keySuffix++}`}><Tag>历史重复</Tag></Col>)
                }
                if (record.dup === 3) {
                    return_tags.push(<Col key={`dup_current-${record.id}-${keySuffix++}`}><Tag>当期重复</Tag></Col>)
                    return_tags.push(<Col key={`dup_history-${record.id}-${keySuffix++}`}><Tag>历史重复</Tag></Col>)
                }
                if (record.return_pkg) {
                    return_tags.push(<Col key={`return_pkg-${record.id}-${keySuffix++}`}><Tag>退件</Tag></Col>)
                }
                if (record.additional_charge_pkg) {
                    return_tags.push(<Col key={`additional_charge_pkg-${record.id}-${keySuffix++}`}><Tag>处理附加费</Tag></Col>)
                }
                if (record.additional_weight_charge) {
                    return_tags.push(<Col key={`additional_weight_charge-${record.id}-${keySuffix++}`}><Tag>重量修改费</Tag></Col>)
                }
                if (record.original_pkg) {
                    return_tags.push(<Col key={`original_pkg-${record.id}-${keySuffix++}`}><Tag>原件</Tag></Col>)
                }
                return <Row gutter={16} style={{ flexWrap: 'wrap' }}>{return_tags}</Row>;
            }
        },
        {
            title: () => {
                return <Space>Dup Details <Tooltip style={{ marginLeft: '2px' }} title="展示系统里,所有相同Tracking Number条目的状态"><QuestionCircleOutlined /></Tooltip></Space>
            },
            dataIndex: 'dup_details',
            key: 'dup_details',
            width: 120,
            fixed: 'left',
            render: (text, record) => {
                return <Button type="link" onClick={() => {
                    dupDetailsButtonHandler(record);
                }}>
                    Dup详情
                </Button>
            }
        },
        {
            title: 'UID',
            dataIndex: 'UID',
            key: 'uid',
            width: 70,
        },
        {
            title: 'Full Tracking',
            dataIndex: 'full_tracking_number',
            key: 'full_tracking',
            render: (text, record) => {
                // if rate json Grd Prefix is null, just show the tracking number without prefix
                if (!(record.rate_json['Grd Prefix'])) {
                    return <span>{record.rate_json['Tracking Number']}</span>
                } else {
                    return <span>{record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']}</span>
                }
            }
        },
        {
            title: 'Surcharge Details',
            dataIndex: 'surcharge_json',
            key: 'surcharge',
            fixed: 'left',
            render: (text, record) => {
                return <Button type="link" onClick={() => {

                    const newModalContent = Object.entries(text).map(([key, value], index) => ({
                        key: index,
                        type: key,
                        amount: value
                    }));
                    setModalContent(newModalContent);
                    setOpenSurcharge(true);
                }}>
                    附加费详情
                </Button>
            }
        },
        {
            title: 'Master EDI Number',
            key: 'Master EDI Number',
            dataIndex: ['rate_json', 'Master EDI No']
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
        },
        {
            title: 'Total Invoice Charge',
            dataIndex: ['rate_json', 'Inv Charge'],
            key: 'Invoice Charge'
        },
        {
            title: 'Bill to Account Number',
            dataIndex: ['rate_json', 'Bill-to Account'],
            key: 'bill_to_account_number',
        },
        {
            title: 'Bill to country',
            dataIndex: ['rate_json', 'Cntry'],
            key: 'bill_to_country',
        },
        {
            title: 'Company Code',
            dataIndex: ['rate_json', 'Co.Cd'],
            key: 'company_code',
        },
        {
            title: 'Ground Tracking ID Prefix',
            dataIndex: ['rate_json', 'Grd Prefix'],
            key: 'ground_tracking_id_prefix',
        },
        {
            title: 'Shipment Date',
            dataIndex: ['rate_json', 'Ship Date'],
            key: 'shipment_date',
        },
        {
            title: 'Service',
            dataIndex: ['rate_json', 'Svc'],
            key: 'service',
        },
        {
            title: 'Ground Service',
            dataIndex: ['rate_json', 'Grd Svc'],
            key: 'ground_service',
        },
        {
            title: 'Reference 1',
            dataIndex: ['rate_json', 'Ref 1'],
            key: 'ref_1',
        },
        {
            title: 'Reference 2',
            dataIndex: ['rate_json', 'Ref 2'],
            key: 'ref_2',
        },
        {
            title: 'Reference 3',
            dataIndex: ['rate_json', 'Ref 3'],
            key: 'ref_3',
        },
        {
            title: 'Customer Department Number',
            dataIndex: ['rate_json', 'Grd Dept No'],
            key: 'grd_dept_no',
        },
        {
            title: 'Net Charge',
            dataIndex: ['rate_json', 'Net Chrg'],
            key: 'net_charge',
        },
        {
            title: 'Currency',
            dataIndex: ['rate_json', 'Curr'],
            key: 'currency',
        },
        {
            title: 'Charge 1',
            dataIndex: ['rate_json', 'Chrg 1'],
            key: 'chrg_1',
        },
        {
            title: 'Freight Amount',
            dataIndex: ['rate_json', 'Freight Amt'],
            key: 'freight_amount',
        },
        {
            title: 'Scale',
            dataIndex: ['rate_json', 'Scale'],
            key: 'scale',
        },
        {
            title: 'Pieces',
            dataIndex: ['rate_json', 'Pcs'],
            key: 'Pcs',
        },
        {
            title: 'Bill Weight',
            dataIndex: ['rate_json', 'Bill Wt'],
            key: 'bill_weight',
        },
        {
            title: 'Original Weight',
            dataIndex: ['rate_json', 'Orig Wt'],
            key: 'original_weight',
        },
        {
            title: 'Multi-Weight',
            dataIndex: ['rate_json', 'Multi-Wt'],
            key: 'multi_weight',
        },
        {
            title: 'weight unit',
            dataIndex: ['rate_json', 'Wt Unit'],
            key: 'weight_unit',
        },
        {
            title: 'Length',
            dataIndex: ['rate_json', 'Length'],
            key: 'length',
        },
        {
            title: 'Width',
            dataIndex: ['rate_json', 'Width'],
            key: 'width',
        },
        {
            title: 'Height',
            dataIndex: ['rate_json', 'Height'],
            key: 'height',
        },
        {
            title: 'Dimension Unit',
            dataIndex: ['rate_json', 'Dim Unit'],
            key: 'dimension_unit',
        },
        {
            title: 'Divisor',
            dataIndex: ['rate_json', 'Divisor'],
            key: 'divisor',
        },
        {
            title: 'Ground Miscellaneous Description 1',
            dataIndex: ['rate_json', 'Grd Misc 1'],
            key: 'grd_misc_desc_1',
        },
        {
            title: 'Ground Miscellaneous Description 2',
            dataIndex: ['rate_json', 'Grd Misc 2'],
            key: 'grd_misc_desc_2',
        },
        {
            title: 'Ground Miscellaneous Description 3',
            dataIndex: ['rate_json', 'Grd Misc 3'],
            key: 'grd_misc_desc_3',
        },
        {
            title: 'Shipper Name',
            dataIndex: ['rate_json', 'Shipper Name'],
            key: 'shipper_name',
        },
        {
            title: 'Shipper Company',
            dataIndex: ['rate_json', 'Shipper Company'],
            key: 'shipper_company',
        },
        {
            title: 'Shipper Dept',
            dataIndex: ['rate_json', 'Shipper Dept'],
            key: 'shipper_dept',
        },
        {
            title: 'Shipper Address 1',
            dataIndex: ['rate_json', 'Shipper Address 1'],
            key: 'shipper_addr1',
        },
        {
            title: 'Shipper Address 2',
            dataIndex: ['rate_json', 'Shipper Address 2'],
            key: 'shipper_addr2',
        },
        {
            title: 'Shipper City',
            dataIndex: ['rate_json', 'Shipper City'],
            key: 'shipper_city',
        },
        {
            title: 'ST',
            dataIndex: ['rate_json', 'ST'],
            key: 'shipper_st',
        },
        {
            title: 'Postal',
            dataIndex: ['rate_json', 'Postal'],
            key: 'postal',
        },
        {
            title: 'US Origin',
            dataIndex: ['rate_json', 'US Origin'],
            key: 'us_origin',
        },
        {
            title: 'Cntry1',
            dataIndex: ['rate_json', 'Cntry1'],
            key: 'cntry1',
        },
        {
            title: 'Region',
            dataIndex: ['rate_json', 'Region'],
            key: 'region',
        },
        {
            title: 'Recipient Name',
            dataIndex: ['rate_json', 'Recipient Name'],
            key: 'recipient_name',
        },
        {
            title: 'Recipient Company',
            dataIndex: ['rate_json', 'Recipient Company'],
            key: 'recipient_company',
        },
        {
            title: 'Recipient Address 1',
            dataIndex: ['rate_json', 'Recipient Address 1'],
            key: 'recipient_addr1',
        },
        {
            title: 'Recipient Address 2',
            dataIndex: ['rate_json', 'Recipient Address 2'],
            key: 'recipient_addr2',
        },
        {
            title: 'Recipient City',
            dataIndex: ['rate_json', 'Recipient City'],
            key: 'recipient_city',
        },
        {
            title: 'ST2',
            dataIndex: ['rate_json', 'ST2'],
            key: 'recipient_st',
        },
        {
            title: 'Postal2',
            dataIndex: ['rate_json', 'Postal2'],
            key: 'recipient_postal',
        },
        {
            title: 'Cntry2',
            dataIndex: ['rate_json', 'Cntry2'],
            key: 'recipient_country',
        },
        {
            title: 'Hndlg',
            dataIndex: ['rate_json', 'Hndlg'],
            key: 'hndlg',
        },
        {
            title: 'Dlvry Date',
            dataIndex: ['rate_json', 'Dlvry Date'],
            key: 'dlvry_date',
        },
        {
            title: 'Time',
            dataIndex: ['rate_json', 'Time'],
            key: 'time',
        },
        {
            title: 'Final',
            dataIndex: ['rate_json', 'Final'],
            key: 'final',
        },
        {
            title: 'Exceptn',
            dataIndex: ['rate_json', 'Exceptn'],
            key: 'exceptn',
        },
        {
            title: 'Attempt Date',
            dataIndex: ['rate_json', 'Attempt Date'],
            key: 'attempt_date',
        },
        {
            title: 'Attempt Time',
            dataIndex: ['rate_json', 'Attempt Time'],
            key: 'attempt_time',
        },
        {
            title: 'Signature',
            dataIndex: ['rate_json', 'Signature'],
            key: 'signature',
        },
        {
            title: 'Svc Area',
            dataIndex: ['rate_json', 'Svc Area'],
            key: 'svc_area',
        },
        {
            title: 'Orig Recip Adr 1',
            dataIndex: ['rate_json', 'Orig Recip Adr 1'],
            key: 'orig_recip_adr_1',
        },
        {
            title: 'Orig Recip Adr 2',
            dataIndex: ['rate_json', 'Orig Recip Adr 2'],
            key: 'orig_recip_adr_2',
        },
        {
            title: 'Original City',
            dataIndex: ['rate_json', 'Original City'],
            key: 'orig_city',
        },
        {
            title: '计算错误',
            dataIndex: 'calculation_error',
            key: 'calculation_error',
        }
    ]
}

export const dupDetailsColumns = [

    {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        fixed: 'left',
    },
    {
        title: 'Tracking Number',
        dataIndex: 'tracking_id',
        key: 'express_or_ground_tracking_id',
        fixed: 'left',
    },
    {
        title: 'Master Tracking',
        dataIndex: ['shipment', 'master_tracking_number'],
        key: 'td_master_tracking',
        fixed: 'left',
    },
    {
        title: 'UID',
        dataIndex: ['customer', 'uid'],
        key: 'uid',
    },
    {
        title: '导入批次编号',
        dataIndex: ['billing_invoice', 'name'],
        key: 'billing_invoice_name',
    },
    {
        title: 'Master EDI Number',
        dataIndex: ['rate_json', 'Master EDI No'],
        key: 'master_edi_number',
    },
    {
        title: 'Invoice Number',
        dataIndex: 'invoice_number',
        key: 'invoice_number',
    },
    {
        title: 'Invoice Date',
        dataIndex: 'invoice_date',
        key: 'invoice_date',
    },
    {
        title: 'Total Invoice Charge',
        dataIndex: ['rate_json', 'Inv Charge'],
        key: 'Invoice Charge'
    },
    {
        title: 'Bill to Account Number',
        dataIndex: ['rate_json', 'Bill-to Account'],
        key: 'bill_to_account_number',
    },
    {
        title: 'Bill to country',
        dataIndex: ['rate_json', 'Cntry'],
        key: 'bill_to_country',
    },
    {
        title: 'Company Code',
        dataIndex: ['rate_json', 'Co.Cd'],
        key: 'company_code',
    },
    {
        title: 'Ground Tracking ID Prefix',
        dataIndex: ['rate_json', 'Grd Prefix'],
        key: 'ground_tracking_id_prefix',
    },
    {
        title: 'Shipment Date',
        dataIndex: ['rate_json', 'Ship Date'],
        key: 'shipment_date',
        sorter: true,
    },
    {
        title: 'Service',
        dataIndex: ['rate_json', 'Svc'],
        key: 'service',
    },
    {
        title: 'Ground Service',
        dataIndex: ['rate_json', 'Grd Svc'],
        key: 'ground_service',
    },
    {
        title: 'Reference 1',
        dataIndex: ['rate_json', 'Ref 1'],
        key: 'ref_1',
    },
    {
        title: 'Reference 2',
        dataIndex: ['rate_json', 'Ref 2'],
        key: 'ref_2',
    },
    {
        title: 'Reference 3',
        dataIndex: ['rate_json', 'Ref 3'],
        key: 'ref_3',
    },
    {
        title: 'Customer Department Number',
        dataIndex: ['rate_json', 'Grd Dept No'],
        key: 'grd_dept_no',
    },
    {
        title: 'Net Charge',
        dataIndex: ['rate_json', 'Net Chrg'],
        key: 'net_charge',
    },
    {
        title: 'Currency',
        dataIndex: ['rate_json', 'Curr'],
        key: 'currency',
    },
    {
        title: 'Charge 1',
        dataIndex: ['rate_json', 'Chrg 1'],
        key: 'chrg_1',
    },
    {
        title: 'Freight Amount',
        dataIndex: ['rate_json', 'Freight Amt'],
        key: 'freight_amount',
    },
    {
        title: 'Scale',
        dataIndex: ['rate_json', 'Scale'],
        key: 'scale',
    },
    {
        title: 'Pieces',
        dataIndex: ['rate_json', 'Pcs'],
        key: 'Pcs',
    },
    {
        title: 'Bill Weight',
        dataIndex: ['rate_json', 'Bill Wt'],
        key: 'bill_weight',
    },
    {
        title: 'Original Weight',
        dataIndex: ['rate_json', 'Orig Wt'],
        key: 'original_weight',
    },
    {
        title: 'Multi-Weight',
        dataIndex: ['rate_json', 'Multi-Wt'],
        key: 'multi_weight',
    },
    {
        title: 'weight unit',
        dataIndex: ['rate_json', 'Wt Unit'],
        key: 'weight_unit',
    },
    {
        title: 'Length',
        dataIndex: ['rate_json', 'Length'],
        key: 'length',
    },
    {
        title: 'Width',
        dataIndex: ['rate_json', 'Width'],
        key: 'width',
    },
    {
        title: 'Height',
        dataIndex: ['rate_json', 'Height'],
        key: 'height',
    },
    {
        title: 'Dimension Unit',
        dataIndex: ['rate_json', 'Dim Unit'],
        key: 'dimension_unit',
    },
    {
        title: 'Divisor',
        dataIndex: ['rate_json', 'Divisor'],
        key: 'divisor',
    },
    {
        title: 'Ground Miscellaneous Description 1',
        dataIndex: ['rate_json', 'Grd Misc 1'],
        key: 'grd_misc_desc_1',
    },
    {
        title: 'Ground Miscellaneous Description 2',
        dataIndex: ['rate_json', 'Grd Misc 2'],
        key: 'grd_misc_desc_2',
    },
    {
        title: 'Ground Miscellaneous Description 3',
        dataIndex: ['rate_json', 'Grd Misc 3'],
        key: 'grd_misc_desc_3',
    },
    {
        title: 'Shipper Name',
        dataIndex: ['rate_json', 'Shipper Name'],
        key: 'shipper_name',
    },
    {
        title: 'Shipper Company',
        dataIndex: ['rate_json', 'Shipper Company'],
        key: 'shipper_company',
    },
    {
        title: 'Shipper Dept',
        dataIndex: ['rate_json', 'Shipper Dept'],
        key: 'shipper_dept',
    },
    {
        title: 'Shipper Address 1',
        dataIndex: ['rate_json', 'Shipper Address 1'],
        key: 'shipper_addr1',
    },
    {
        title: 'Shipper Address 2',
        dataIndex: ['rate_json', 'Shipper Address 2'],
        key: 'shipper_addr2',
    },
    {
        title: 'Shipper City',
        dataIndex: ['rate_json', 'Shipper City'],
        key: 'shipper_city',
    },
    {
        title: 'ST',
        dataIndex: ['rate_json', 'ST'],
        key: 'shipper_st',
    },
    {
        title: 'Postal',
        dataIndex: ['rate_json', 'Postal'],
        key: 'postal',
    },
    {
        title: 'US Origin',
        dataIndex: ['rate_json', 'US Origin'],
        key: 'us_origin',
    },
    {
        title: 'Cntry1',
        dataIndex: ['rate_json', 'Cntry1'],
        key: 'cntry1',
    },
    {
        title: 'Region',
        dataIndex: ['rate_json', 'Region'],
        key: 'region',
    },
    {
        title: 'Recipient Name',
        dataIndex: ['rate_json', 'Recipient Name'],
        key: 'recipient_name',
    },
    {
        title: 'Recipient Company',
        dataIndex: ['rate_json', 'Recipient Company'],
        key: 'recipient_company',
    },
    {
        title: 'Recipient Address 1',
        dataIndex: ['rate_json', 'Recipient Address 1'],
        key: 'recipient_addr1',
    },
    {
        title: 'Recipient Address 2',
        dataIndex: ['rate_json', 'Recipient Address 2'],
        key: 'recipient_addr2',
    },
    {
        title: 'Recipient City',
        dataIndex: ['rate_json', 'Recipient City'],
        key: 'recipient_city',
    },
    {
        title: 'ST2',
        dataIndex: ['rate_json', 'ST2'],
        key: 'recipient_st',
    },
    {
        title: 'Postal2',
        dataIndex: ['rate_json', 'Postal2'],
        key: 'recipient_postal',
    },
    {
        title: 'Cntry2',
        dataIndex: ['rate_json', 'Cntry2'],
        key: 'recipient_country',
    },
    {
        title: 'Hndlg',
        dataIndex: ['rate_json', 'Hndlg'],
        key: 'hndlg',
    },
    {
        title: 'Dlvry Date',
        dataIndex: ['rate_json', 'Dlvry Date'],
        key: 'dlvry_date',
    },
    {
        title: 'Time',
        dataIndex: ['rate_json', 'Time'],
        key: 'time',
    },
    {
        title: 'Final',
        dataIndex: ['rate_json', 'Final'],
        key: 'final',
    },
    {
        title: 'Exceptn',
        dataIndex: ['rate_json', 'Exceptn'],
        key: 'exceptn',
    },
    {
        title: 'Attempt Date',
        dataIndex: ['rate_json', 'Attempt Date'],
        key: 'attempt_date',
    },
    {
        title: 'Attempt Time',
        dataIndex: ['rate_json', 'Attempt Time'],
        key: 'attempt_time',
    },
    {
        title: 'Signature',
        dataIndex: ['rate_json', 'Signature'],
        key: 'signature',
    },
    {
        title: 'Svc Area',
        dataIndex: ['rate_json', 'Svc Area'],
        key: 'svc_area',
    },
    {
        title: 'Orig Recip Adr 1',
        dataIndex: ['rate_json', 'Orig Recip Adr 1'],
        key: 'orig_recip_adr_1',
    },
    {
        title: 'Orig Recip Adr 2',
        dataIndex: ['rate_json', 'Orig Recip Adr 2'],
        key: 'orig_recip_adr_2',
    },
    {
        title: 'Original City',
        dataIndex: ['rate_json', 'Original City'],
        key: 'orig_city',
    },
]
