import React, { useState } from 'react';
import { Button } from 'antd';
// this functional components aim to solve the problem of double clicking
// sending two exactly same requests to the server
// the button needs to be disabled after onClick event
// and enabled after the onClick event is finished

function CustomizedButton ({ onClick, children, ...props }) {
    const [loading, setLoading] = useState(false);
    const handleClick = async () => {
        setLoading(true);
        await onClick();
        setLoading(false);
    }
    return (
        <Button onClick={handleClick} disabled={loading} {...props}>
            {children}
        </Button>
    )
}

export default CustomizedButton;