import React, {useEffect} from 'react';
import {Modal, Table, Spin, Space, Form, Input} from 'antd';
function DisplayReceivableEditingModal ({selectedRowKey, data, open, setOpen, loading, onSubmit, success}) {
    const [form] = Form.useForm();
    useEffect(() => {
        if (success) {
            form.resetFields();
            setOpen(false);
        }
    }, [success])
    const handleOk = () => {
        form.validateFields().then(values => {
            onSubmit(values);
        }).catch(error => {
            console.log('Validate Failed:', error);
        });
    };
    const positiveNumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^-?[0-9]+(.[0-9]+)?$/.test(value)) {
                reject('请输入可识别的数字!');
            } else if (value && value.length > 20) {
                reject('最多20位!');
            } else {
                resolve();
            }
        });
    }
    useEffect(() => {

        if (open && selectedRowKey !== null) {
            const selectedItem = data ? data.results.find(item => item.id === selectedRowKey) : null;
            if (selectedItem) {
                form.setFieldsValue({
                    invoice_amount: selectedItem.invoice_amount,
                    invoice_number: selectedItem.invoice_number,
                });
            }
        }
    }, [selectedRowKey, data, open, form]);
    return (
        <Modal
            title="编辑"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            centered
            confirmLoading={loading}
        >
            <Form
                form={form}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '15%',
                    marginRight: '20%',
                    maxWidth: '60%'
                }}
            >
                <Form.Item label='账单名称' name='invoice_number' rules={[{ required: true, message: '请输入账单名称!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label='账单金额' name='invoice_amount' rules={[{ required: true, message: '请输入账单金额!' }, { validator: positiveNumberOnly }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default DisplayReceivableEditingModal;