import React from 'react';
import { Modal, Input, Typography, Space, Button } from 'antd';
const { Text } = Typography;
function DeleteSurchargeWithDisputeModal({ open, setOpen, disputeBasis, setDisputeBasis, onSubmitWithoutDispute, onSubmitWithDispute }) {
    return (
        <Modal
            title=""
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    回到编辑附加费
                </Button>,
                <Button
                    key="notDispute"
                    color="danger"
                    variant="solid"
                    onClick={() => {
                        onSubmitWithoutDispute();
                        setOpen(false);
                    }}>
                    不需要Dispute
                </Button>,
                <Button
                    key="dispute"
                    color="primary"
                    variant="solid"
                    onClick={() => {
                        onSubmitWithDispute();
                        setOpen(false);
                    }}>
                    Dispute
                </Button>
            ]}
        >
            <Space direction="vertical">
                <Text>
                    检测到有删除的附加费项目,是否对删除的附加费项目进行Dispute?
                </Text>
                <Text>
                    如若Dispute, 请填写Dispute原因:
                </Text>
                <Input.TextArea
                    value={disputeBasis}
                    onChange={
                        (e) => setDisputeBasis(e.target.value)
                    } />
            </Space>
        </Modal>
    )
}

export default DeleteSurchargeWithDisputeModal;