import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { logoutUser } from '../auth/authSlice';

const baseUrl = process.env.REACT_APP_REACT_APP_BACKEND_BASE_URL;
const baseQuery = fetchBaseQuery({baseUrl: baseUrl, credentials: "include"});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    api.dispatch(logoutUser())
    localStorage.setItem('sessionTimeout', 'true');
    window.location.href = '/login/'
  }
  return result
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Client'],
  endpoints: builder => ({
    getClient: builder.query({
      query: (searchData) => {
        return Object.keys(searchData).length > 0
      ? {
          url: 'accounts/get-customer/',
          method: 'GET',
          params: { 
            username: searchData.customerName,
            uid: searchData.customerId,
            status: searchData.customerStatus,
            rate_template: searchData.customerCategory,
            user: searchData.customerAssignedTo,
            business_type: searchData.businessType,
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        }
      : {
          url: 'accounts/get-customer/',
          method: 'GET',
          params: { 
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        };
      },
      providesTags: ['Client'],
    }),
    createClient: builder.mutation({
      query: (data) => ({
        url: 'accounts/create-customer/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Client', 'CustomerUidSearchSelect'],
    }),
    updateClient: builder.mutation({
      query: (data) => ({
        url: `accounts/update-customer/${data.id}/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Client', 'CustomerUidSearchSelect'],
    }),
    updateClientMWT: builder.mutation({
      query: (data) => ({
        url: `accounts/update-customer-mwt/${data.id}/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Client', 'CustomerUidSearchSelect'],
    }),
    bulkUpdateClientStatus: builder.mutation({
      query: (data) => ({
        url: `accounts/bulk-update-customer-status/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Client', 'CustomerUidSearchSelect'],
    }),
    updateClientDateInterval: builder.mutation({
      query: (data) => ({
        url: `accounts/update-customer-date-interval/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Client', 'CustomerUidSearchSelect'],
    }),
    deleteClient: builder.mutation({
      query: (data) => ({
        url: `accounts/delete-customer/`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['Client', 'CustomerUidSearchSelect'],
    }),
    getUser: builder.query({
      query: () => ({
        url: 'accounts/get-user/',
        method: 'GET',
      }),
    }),
    getRateTemplate: builder.query({
      query: () => ({
        url: 'accounts/get-rateTemplate/',
        method: 'GET',
      }),
      providesTags: ['RateTemplate'],
    }),
    getLogisticsSupplier: builder.query({
      query: () => ({
        url: 'accounts/get-all-logistics-supplier/',
        method: 'GET',
      }),
      providesTags: ['LogisticsSupplier'],
    }),
    getLogisticsService: builder.query({
      query: (searchData) => {
        return Object.keys(searchData).length > 0
      ? {
          url: 'accounts/get-logistics-services/',
          method: 'GET',
          params: { 
            logistics_supplier: searchData.logisticsSupplier,
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        }
      : {
          url: 'accounts/get-logistics-services/',
          method: 'GET',
          params: { 
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        };
      }
      ,providesTags: ['LogisticsService'],
    }),
    createLogisticsService: builder.mutation({
      query: (data) => ({
        url: 'accounts/create-logistics-service/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['LogisticsService', 'LogisticsSupplier'],
    }),
    updateLogisticsService: builder.mutation({
      query: (data) => ({
        url: `accounts/update-logistics-service/${data.id}/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['LogisticsService'],
    }),
    getQuoteSheet: builder.mutation({
      query: (data) => ({
        url: 'accounts/get-quote-sheet/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['QuoteSheet'],
    }),
    getRateExcel: builder.query({
      query: (searchData) => {
        return Object.keys(searchData).length > 0
      ? {
          url: 'accounts/get-rate-excel/',
          method: 'GET',
          params: {
            logistics_service: searchData.logisticsService,
            logistics_supplier: searchData.logisticsSupplier,
            rate_template: searchData.rateTemplate,
            effective_date: searchData.effectiveDate,
            user: searchData.user,
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        }
      : {
          url: 'accounts/get-rate-excel/',
          method: 'GET',
          params: { 
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        };
      },
      providesTags: ['RateExcel'],
    }),
    getAllCustomerUid: builder.query({
      query: (data) => ({
        url: 'accounts/get-all-customer-uid/',
        method: 'GET',
      }),
      providesTags: ['CustomerUidSearchSelect']
    }),
    getLogisticsServiceByName: builder.query({
      query: (data) => ({
        url: 'accounts/get-all-logistics-service-by-name/',
        method: 'GET',
      }),
      providesTags: ['logisticsService']
    }),
    verifyQuoteSheet: builder.mutation({
      query: (data) => ({
        url: 'accounts/verify-quote-sheet/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['RateExcel'],
    }),
    getBillingInvoice: builder.query({
      query: (data) => ({
        url: 'accounts/get-billing-invoices/',
        method: 'GET',
        params: data,
      }),
      providesTags: ['BillingInvoice'],
    }),
    uploadBillingInvoice: builder.mutation({
      query: (data) => ({
        url: 'accounts/upload-billing-invoice/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['BillingInvoice', 'BillingProcess', 'BillingDetails', 'BillingInvoiceSearchSelect'],
    }),
    deleteBillingInvoice: builder.mutation({
      query: (data) => ({
        url: `accounts/delete-billing-invoice/`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['BillingInvoice', 'BillingProcess', 'BillingDetails', 'BillingInvoiceSearchSelect'],
    }),
    getBillingFileByInvoiceId: builder.query({
      query: (data) => ({
        url: `accounts/get-billing-files-by-invoice/${data}`,
        method: 'GET',
      }),
    }),
    getBillingProcess: builder.query({
      query: (searchData) => {
        return Object.keys(searchData).length > 0
      ? {
          url: 'accounts/get-billing-process/',
          method: 'GET',
          params: {
            billing_invoice: searchData.billingInvoice,
            user: searchData.user,
            created_at_before: searchData.createdAtBefore,
            created_at_after: searchData.createdAtAfter,
            customer: searchData.customer,
            status: searchData.status,
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        }
      : {
          url: 'accounts/get-billing-process/',
          method: 'GET',
          params: { 
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        };
      },
      providesTags: ['BillingProcess'],
    }),
    getBillingInvoiceNames: builder.query({
      query: (data) => ({
        url: 'accounts/get-billing-invoices-names/',
        method: 'GET',
      }),
      providesTags: ['BillingInvoiceSearchSelect']
    }),
    getBillingDetails: builder.query({
      query: (searchData) => {
        const params = {
          page_size: searchData.pageSize,
          curr_page: searchData.currentPage,
          status: searchData.status,
          bill_to_account_number: searchData.billToAccountNumber,
          invoice_date_before: searchData.invoiceDateBefore,
          invoice_date_after: searchData.invoiceDateAfter,
          invoice_number: searchData.invoiceNumber,
          logistics_service: searchData.logisticsService,
          td_master_tracking: searchData.tdMasterTracking,
          uid: searchData.uid,
          billing_invoice: searchData.billingInvoice,
          sort_field: searchData.sortField,
          sort_order: searchData.sortOrder,
          shipment_date_before: searchData.shipmentDateBefore,
          shipment_date_after: searchData.shipmentDateAfter,
          id_to_download: searchData.idToDownload,
          isDownload: searchData.isDownload,
          dispute_status: searchData.disputeStatus,
        };
        const bodyData = new FormData();
        if (searchData.trackingNumber) {
          bodyData.append('tracking_number', searchData.trackingNumber);
        }
        
        return {
          url: 'accounts/get-billing-details/',
          method: 'POST',
          params: params,
          body: bodyData,
          formData: true,
        };
      },
      providesTags: ['BillingDetails'],
    }),
    getBillingDetailsWithError: builder.query({
      query: (searchData) => {
        return Object.keys(searchData).length > 0
      ? {
          url: 'accounts/get-billing-details-with-error/',
          method: 'GET',
          params: {
            billing_invoice: searchData.billingInvoice,
            tracking_number: searchData.trackingNumber,
            uid: searchData.uid,
            status: searchData.currentTab,
            dup_status: searchData.dup_status,
            service_match: searchData.service_match,
            logistics_service: searchData.logisticsService,
            master_mismatch_status: searchData.masterMismatchStatus,
            user_defined_exceptions: searchData.userDefinedExceptions,
            zzz_price: searchData.zzzPrice,
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        }
      : {
          url: 'accounts/get-billing-details-with-error/',
          method: 'GET',
          params: { 
            status: searchData.currentTab,
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        };
      },
      providesTags: ['BillingDetailsWithError'],
    }),
    deleteTestFiles: builder.mutation({
      query: (data) => ({
        url: 'accounts/delete-all-test-files/',
        method: 'DELETE',
      }),
      invalidatesTags: ['BillingInvoice', 'BillingDetailsWithError', 'BillingDetails', 'BillingProcess', 'BillingInvoice'],
    }),
    updateUid: builder.mutation({
      query: (data) => ({
        url: 'accounts/update-uid-with-billing-details/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['BillingInvoice', 'BillingDetailsWithError', 'BillingDetails', 'BillingProcess', 'BillingInvoice'],
    }),
    batchUpdateUid: builder.mutation({
      query: (data) => ({
        url: 'accounts/batch-update-uid-with-billing-details/',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json' // 明确指定发送内容为 JSON
        }
        // formData: true,
      }),
      invalidatesTags: ['BillingInvoice', 'BillingDetailsWithError', 'BillingDetails', 'BillingProcess', 'BillingInvoice'],
    }),
    getAsyncManagement: builder.query({
      query: (searchData) => {
        return Object.keys(searchData).length > 0
            ? {
              url: 'accounts/get-async-management/',
              method: 'GET',
              params: {
                record_type: searchData.record_type,
                user: searchData.user,
                created_at_before: searchData.createdAtBefore,
                created_at_after: searchData.createdAtAfter,
                status: searchData.status,
                page_size: searchData.pageSize,
                curr_page: searchData.currentPage,
              },
            }
            : {
              url: 'accounts/get-async-management/',
              method: 'GET',
              params: {
                page_size: searchData.pageSize,
                curr_page: searchData.currentPage,
              },
            };
      },
      providesTags: ['AsyncManagement'],
    }),
    deleteAsyncManagement: builder.mutation({
      query: (data) => ({
        url: `accounts/delete-async-management/`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['AsyncManagement'],
    }),
    exportUidFiles: builder.mutation({
      query: (data) => ({
        url: `/accounts/export-uid-files/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AsyncManagement'],
    }),
    getRecordType: builder.query({
      query: () => ({
        url: 'accounts/get-record-type/',
        method: 'GET',
      }),
      providesTags: ['RecordType'],
    }),
    disputeBillingDetails: builder.mutation({
      query: (data) => ({
        url: 'accounts/dispute-billing-details/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingDetails', 'BillingDetailsWithError', 'BillingProcess'],
    }),
    statusUpdateHandler: builder.mutation({
      query: (data) => ({
        url: 'accounts/billing-details-status-update/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingDetails', 'BillingDetailsWithError', 'BillingProcess'],
    }),
    statusUpdateHandlerV2: builder.mutation({
      query: (data) => ({
        url: 'accounts/billing-details-status-update-v2/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingDetails', 'BillingDetailsWithError', 'BillingProcess'],
    }),
    GenerateBillingBatchDetails: builder.mutation({
      query: (data) => {
        const body = {
          billing_batch_id: data.billing_batch_id
        }
        if (data.ids){
          body['ids'] = data.ids;
        }
        return {
          url: 'accounts/generate-billing-batch-billing-details/',
          method: 'POST',
          params: {
            status: data.status,
            bill_to_account_number: data.billToAccountNumber,
            invoice_date_before: data.invoiceDateBefore,
            invoice_date_after: data.invoiceDateAfter,
            invoice_number: data.invoiceNumber,
            logistics_service: data.logisticsService,
            td_master_tracking: data.tdMasterTracking,
            tracking_number: data.trackingNumber,
            uid: data.uid,
            billing_invoice: data.billingInvoice,
            shipment_date: data.shipmentDate,
          },
          body: body,
        }
      },
      invalidatesTags: ['BillingDetails', 'Invoice', 'HSPCalculationDetails', 'GroundCalculationDetails'],
    }),
    GenerateBillingBatchShipments: builder.mutation({
      query: (data) => {
        const body = {
          billing_batch_id: data.billing_batch_id
        }
        if (data.ids) {
          body['ids'] = data.ids;
        }
        return {
          url: 'accounts/generate-billing-batch-shipments/',
          method: 'POST',
          params: {
            status: data.status,
            master_tracking_number: data.masterTrackingNumber,
            uid: data.uid,
            shipment_date: data.shipmentDate,
          },
          body: body,
        }
      },
      invalidatesTags: ['BillingDetails', 'Invoice', 'HSPCalculationDetails', 'GroundCalculationDetails'],
    }),
    SunkCost: builder.mutation({
      query: (data) => ({
        url: 'accounts/sunk-cost-billing-details/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingDetails', 'BillingDetailsWithError', 'BillingProcess'],
    }),
    getFuelSurchargeManagement: builder.query({
      query: (searchData) => {
        const field = searchData.sorter?.field
        const order = searchData.sorter?.order === 'descend' ? `-${field}` : field;
        return Object.keys(searchData).length > 0
        ? {
            url: 'accounts/get-fuel-surcharge-management/',
            method: 'GET',
            params: {
              logistics_service: searchData.logisticsService,
              eff_time: searchData.allMondays,
              curr_page: searchData.currentPage,
              page_size: searchData.pageSize,
              ordering: order
            },
        }
        : {
            url: 'accounts/get-fuel-surcharge-management/',
            method: 'GET',
            params: {
              curr_page: searchData.currentPage,
              page_size: searchData.pageSize,
              ordering: order
            },
        }
      },
      providesTags: ['FuelSurchargeManagement'],
    }),
    updateFuelSurchargeManagement: builder.mutation({
      query: (data) => ({
        url: 'accounts/update-fuel-surcharge-management/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['FuelSurchargeManagement'],
    }),
    getNonStandardService: builder.query({
      query: (searchData) => {
        return Object.keys(searchData).length > 0
      ? {
          url: 'accounts/get-non-standard-service/',
          method: 'GET',
          params: {
            bill_to_account_number: searchData.billToAccountNumber,
            invoice_date_before: searchData.invoiceDateBefore,
            invoice_date_after: searchData.invoiceDateAfter,
            invoice_number: searchData.invoiceNumber,
            logistics_service: searchData.logisticsService,
            tracking_number: searchData.trackingNumber,
            uid: searchData.uid,
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
            billing_invoice: searchData.billingInvoice,
            status: searchData.status,
          },
        }
      : {
          url: 'accounts/get-non-standard-service/',
          method: 'GET',
          params: {
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        };
      },
      providesTags: ['NonStandardServiceBillingDetails'],
    }),
    getInvoice: builder.query({
      query: (searchData) => {
        return Object.keys(searchData).length > 0
      ? {
          url: 'accounts/get-invoice/',
          method: 'GET',
          params: {
            uid: searchData.uid,
            billing_invoice: searchData.billingInvoice,
            status: searchData.status,
            logistics_service: searchData.logisticsService,
            billing_batch_id: searchData.billingBatchId,
            bill_time_before: searchData.billTimeBefore,
            bill_time_after: searchData.billTimeAfter,
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        }
      : {
          url: 'accounts/get-invoice/',
          method: 'GET',
          params: {
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        };
      },
      providesTags: ['Invoice'],
    }),
    downloadBillingBatch: builder.mutation({
      query: (data) => ({
        url: 'accounts/download-billing-batch/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['Invoice'],
    }),
    updateMasterTracking: builder.mutation({
      query: (data) => ({
        url: 'accounts/update-master-tracking/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingDetailsWithError', 'BillingDetails', 'BillingProcess', 'BillingInvoice'],
    }),
    getTrackingMasterTracking: builder.mutation({
      query: (data) => ({
        url: 'accounts/get-tracking-master-tracking/',
        method: 'POST',
        body: data,
      }),
      // invalidatesTags: ['BillingDetailsWithError', 'BillingDetails', 'BillingProcess', 'BillingInvoice'],
    }),
    modifyService: builder.mutation({
      query: (data) => ({
        url: 'accounts/modify-service/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingDetailsWithError']
    }),
    getNonOriginalBillingDetails: builder.query({
      query: (searchData) => {
        return Object.keys(searchData).length > 0
      ? {
          url: 'accounts/get-non-original-billing-details/',
          method: 'GET',
          params: {
            billing_invoice: searchData.billingInvoice,
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        }
      : {
          url: 'accounts/get-non-original-billing-details/',
          method: 'GET',
          params: {
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
          },
        };
      },
      providesTags: ['NonOriginalBillingDetails'],
    }),
    getChargeCode: builder.query({
      query: () => ({
        url: 'accounts/get-charge-code/',
        method: 'GET',
      }),
      providesTags: ['ChargeCode'],
    }),
    ConvertZZZToCharge: builder.mutation({
      query: (data) => ({
        url: 'accounts/convert-zzz-to-charge/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingDetailsWithError', 'BillingDetails', 'BillingProcess', 'BillingInvoice'],
    }),
    getAWSFileManagement: builder.query({
      query: (searchData) => {
        const field = searchData.sorter?.field
        const order = searchData.sorter?.order === 'descend' ? `-${field}` : field;
        return Object.keys(searchData).length > 0
            ? {
              url: 'accounts/get-aws-file-manager/',
              method: 'GET',
              params: {
                logistics_service: searchData.logisticsService,
                eff_time: searchData.allMondays,
                curr_page: searchData.currentPage,
                page_size: searchData.pageSize,
                ordering: order
              },
            }
            : {
              url: 'accounts/get-aws-file-manager/',
              method: 'GET',
              params: {
                curr_page: searchData.currentPage,
                page_size: searchData.pageSize,
                ordering: order
              },
            }
      },
      providesTags: ['FuelSurchargeManagement'],
    }),
    updateUidWithoutStatusChange: builder.mutation({
      query: (data) => ({
        url: 'accounts/update-uid-without-status-change/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['BillingDetails'],
    }),
    splitUserChange: builder.mutation({
      query: (data) => ({
        url: 'accounts/split-user-change/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['BillingDetails'],
    }),
    ShipmentRateCalculation: builder.mutation({
      query: (data) => ({
        url: 'shipment_rate/shipment-rate-calculation/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['BillingDetails', 'HSPCalculationDetails', 'GroundCalculationDetails'],
    }),
    ReShipmentRateCalculation: builder.mutation({
      query: (data) => ({
        url: 'shipment_rate/re-shipment-rate-calculation/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['BillingDetails', 'Invoice', 'HSPCalculationDetails', 'GroundCalculationDetails'],
    }),
    getGroundCalculationDetails: builder.query({
      query: (searchData) => {
        const params = {
          page_size: searchData.pageSize,
          curr_page: searchData.currentPage,
          master_tracking_number: searchData.masterTrackingNumber,
          uid: searchData.uid,
          shipment_date: searchData.shipmentDate,
          invoice: searchData.invoice,
          status: searchData.status,
          id_to_download: searchData.idToDownload,
          sort_field: searchData.sortField,
          sort_order: searchData.sortOrder,
        };
        return {
          url: 'accounts/get-ground-calculation_details/',
          method: 'GET',
          params: params,
        };
      },
      providesTags: ['GroundCalculationDetails'],
    }),
    getHSPCalculationDetails: builder.query({
      query: (searchData) => {
        const params = {
          status: searchData.status,
          page_size: searchData.pageSize,
          curr_page: searchData.currentPage,
          logistics_service: searchData.logisticsService,
          td_master_tracking: searchData.tdMasterTracking,
          tracking_number: searchData.trackingNumber,
          billing_invoice: searchData.billingInvoice,
          invoice: searchData.invoice,
          uid: searchData.uid,
          shipment_date: searchData.shipmentDate,
          id_to_download: searchData.idToDownload,
          sort_field: searchData.sortField,
          sort_order: searchData.sortOrder,
        };
        return {
          url: 'accounts/get-home-sp-calculation-details/',
          method: 'GET',
          params: params,
        };
      },
      providesTags: ['HSPCalculationDetails'],
    }),
    getDupDetails: builder.query({
      query: (data) => {
        return {
          url: 'accounts/get-dup-details/',
          method: 'GET',
          params: data,
        }
      }
    }),
    CompulsoryPayment: builder.mutation({
      query: (data) => ({
        url: 'accounts/compulsory-payment/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingDetails', 'BillingDetailsWithError', 'BillingProcess'],
    }),
    TemporaryRetrieveMasterTracking: builder.mutation({
      query: (data) => ({
        url: 'accounts/temporary-update-status/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingDetails', 'BillingDetailsWithError', 'BillingProcess'],
    }),
    GroundShipmentRateCalculation: builder.mutation({
      query: (data) => ({
        url: 'shipment_rate/ground-shipment-rate-calculation/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['BillingDetails', 'HSPCalculationDetails', 'GroundCalculationDetails'],
    }),
    createRateTemplate: builder.mutation({
      query: (data) => ({
        url: 'accounts/create-rate-template/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['RateTemplate'],
    }),
    addNewFuelSurcharge: builder.mutation({
      query: (data) => ({
        url: 'accounts/add-new-fuel-surcharge/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['FuelSurchargeManagement'],
    }),
    GetRuleExpressions: builder.query({
      query: (searchData) => {
        const params = {
          page_size: searchData.pageSize,
          curr_page: searchData.currentPage,
        };
        return {
          url: 'accounts/get-rule-expressions/',
          method: 'GET',
          params: params,
        };
      },
      providesTags: ['RuleExpressions'],
    }),
    createRuleExpression: builder.mutation({
      query: (data) => ({
        url: 'accounts/create-new-rule-expression/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['RuleExpressions'],
    }),
    deleteRuleExpression: builder.mutation({
      query: (data) => ({
        url: 'accounts/delete-rule-expression/',
        method: 'POST',
        body: data,
        formData: true,
      }),
      invalidatesTags: ['RuleExpressions'],
    }),
    GetRuleExpressionsNames: builder.query({
      query: () => {
        return {
          url: 'accounts/get-rule-expressions-names/',
          method: 'GET',
        };
      },
    }),
    ReevaluateBillingDetails: builder.mutation({
      query: (data) => {
        return {
          url: 'accounts/reevaluate-billing-details/',
          method: 'POST',
          body: data,
          formData: true,
        }
      },
      invalidatesTags: ['BillingDetails', 'BillingDetailsWithError', 'BillingProcess'],
    }),
    getAllCustomerUserGroup: builder.query({
      query: () => ({
        url: 'accounts/get-all-customer-user-group/',
        method: 'GET',
      }),
    }),
    updateBillingStatus: builder.mutation({
      query: (searchData) => {
        return {
          url: 'accounts/update-billing-status/',
          method: 'POST',
          params: {
            bill_to_account_number: searchData.billToAccountNumber,
            invoice_date_before: searchData.invoiceDateBefore,
            invoice_date_after: searchData.invoiceDateAfter,
            invoice_number: searchData.invoiceNumber,
            logistics_service: searchData.logisticsService,
            tracking_number: searchData.trackingNumber,
            uid: searchData.uid,
            page_size: searchData.pageSize,
            curr_page: searchData.currentPage,
            billing_invoice: searchData.billingInvoice,
            status: searchData.status,
          },
          body: {
            'data': searchData,
            'invoice_name': searchData.invoiceName,
          },
        }
      },
      invalidatesTags: ['NonStandardServiceBillingDetails']
    }),
    labelReuse: builder.mutation({
      query: (data) => ({
        url: 'accounts/label-reuse/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingDetails', 'BillingDetailsWithError', 'BillingProcess'],
    }),
    getBillingDetailsSummary: builder.query({
      query: (data) => {
        let params = {
          uid: data.uid,
          logistics_service: data.logistics_service,
          page_size: data.pageSize,
          curr_page: data.current,
        }
        if (data?.invoice_date) {
          params.invoice_date_after = data.invoice_date[0].substring(0,10);
          params.invoice_date_before = data.invoice_date[1].substring(0,10);
        }
        return {
          url: 'accounts/get-billing-details-summary/',
          method: 'GET',
          params: params,
        }
      },
      providesTags: ['BillingDetailsSummary'],
    }),
    getInvoiceByName: builder.query({
      query: (data) => {
        return {
          url: 'accounts/get-invoice-by-name/',
          method: 'GET',
        }
      }
    }),
    getRuleExpressionNames: builder.query({
      query: () => {
        return {
          url: 'accounts/get-rule-expression-without-pagination/',
          method: 'GET',
        }
      }
    }),
    getUserByDepartment: builder.query({
      query: (department) => {
        const params = {
          'department': department
        }
        return {
          url: 'accounts/get-user-by-department/',
          method: 'GET',
          params: params,
        }
      }
    }),
    editBillingBatchInfo: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append('invoice_id', data.invoice_id);
        formData.append('actual_billed_amount', data.actual_billed_amount);
        formData.append('actual_billed_time', data.actual_billed_time);
        return {
          url: 'accounts/edit-billing-batch-info/',
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
      invalidatesTags: ['Invoice']
    }),
    getAccountReceivable: builder.query({
      query: (data) => {
        let params = {
          customer: data.customer,
          billing_cycle: data.billing_cycle,
          contact: data.contact,
          biller: data.biller,
          invoice_number: data.invoice_number,
          business_type: data.business_type,
          remittee: data.remittee,
          customer_name: data.customer_name,
          account_invoice_status: data.account_invoice_status,
          overdue_days: data.overdue_days,
          page_size: data.pageSize,
          curr_page: data.current,
        }
        if (data?.invoice_date) {
          params.invoice_date_after = data.invoice_date[0].substring(0,10);
          params.invoice_date_before = data.invoice_date[1].substring(0,10);
        }
        if (data?.revenue_confirm_date) {
          params.revenue_confirm_date = data.revenue_confirm_date.substring(0,10);

        }
        if (data?.accounting_confirm_date) {
          params.accounting_confirm_date = data.accounting_confirm_date.substring(0,10);
        }
        return {
          url: 'accounts/get-account-receivable/',
          method: 'POST',
          params: params,
        }
      },
      providesTags: ['AccountReceivable']
    }),
    createAccountReceivable: builder.mutation({
      query: (data) => {
        return {
          url: 'accounts/create-account-receivable/',
          method: 'POST',
          body: data,
          formData: true,
        }
      },
      invalidatesTags: ['AccountReceivable']
    }),
    makePayment: builder.mutation({
      query: (data) => {
        return {
          url: 'accounts/make-payment/',
          method: 'POST',
          body: data,
          formData: true,
        }
      },
      invalidatesTags: ['AccountReceivable']
    }),
    updateAccountReceivable: builder.mutation({
      query: (data) => {
        let request = {};
        if (data?.revenue_confirm_date) {
          request.revenue_confirm_date = data.revenue_confirm_date;
        }
        if (data?.accounting_confirm_date) {
          request.accounting_confirm_date = data.accounting_confirm_date;
        }
        return {
          url: `accounts/update-account-receivable/${data.invoice_id}/`,
          method: 'PATCH',
          body: request,
        }
      },
      invalidatesTags: ['AccountReceivable']
    }),
    editingAccountReceivable: builder.mutation({
      query: (data) => {
        return {
          url: `accounts/editing-account-receivable/${data.invoice_id}/`,
          method: 'PATCH',
          body: data,
        }
      },
      invalidatesTags: ['AccountReceivable']
    }),
    adjustSurcharges: builder.mutation({
      query: (data) => {
        return {
          url: 'accounts/adjust-surcharges/',
          method: 'POST',
          body: data,
          formData: true,
        }
      },
      invalidatesTags: ['BillingDetails', 'BillingDetailsWithError', 'BillingProcess'],
    }),
    disputeSurcharges: builder.mutation({
      query: (data) => {
        return {
          url: 'accounts/dispute-surcharges/',
          method: 'POST',
          body: data,
          formData: true,
        }
      },
      invalidatesTags: ['BillingDetails', 'BillingDetailsWithError', 'BillingProcess'],
    }),
    getAccountReceivableAndRiskControlOperationLog: builder.query({
      query: (invoice_id) => {
        return {
          url: `accounts/get-account-receivable-and-risk-control-operation-log/${invoice_id}`,
          method: 'GET',
        }
      }
    })
  })
})

export const {
  useGetUserQuery,
  useGetClientQuery, 
  useUpdateClientMutation,
  useBulkUpdateClientStatusMutation,
  useUpdateClientMWTMutation,
  useCreateClientMutation,
  useDeleteClientMutation,
  useGetRateTemplateQuery,
  useGetLogisticsSupplierQuery,
  useGetLogisticsServiceQuery,
  useCreateLogisticsServiceMutation,
  useUpdateLogisticsServiceMutation,
  useGetQuoteSheetMutation,
  useGetRateExcelQuery,
  useGetAllCustomerUidQuery,
  useGetLogisticsServiceByNameQuery,
  useVerifyQuoteSheetMutation,
  useGetBillingInvoiceQuery,
  useUploadBillingInvoiceMutation,
  useDeleteBillingInvoiceMutation,
  useGetBillingFileByInvoiceIdQuery,
  useGetBillingProcessQuery,
  useGetBillingInvoiceNamesQuery,
  useGetBillingDetailsQuery,
  useGetBillingDetailsWithErrorQuery,
  useDeleteTestFilesMutation,
  useUpdateUidMutation,
  useBatchUpdateUidMutation,
  useGetAsyncManagementQuery,
  useDeleteAsyncManagementMutation,
  useExportUidFilesMutation,
  useGetRecordTypeQuery,
  useDisputeBillingDetailsMutation,
  useStatusUpdateHandlerMutation,
  useStatusUpdateHandlerV2Mutation,
  useGenerateBillingBatchDetailsMutation,
  useSunkCostMutation,
  useGetFuelSurchargeManagementQuery,
  useUpdateFuelSurchargeManagementMutation,
  useGetNonStandardServiceQuery,
  useGetInvoiceQuery,
  useDownloadBillingBatchMutation,
  useUpdateMasterTrackingMutation,
  useGetTrackingMasterTrackingMutation,
  useModifyServiceMutation,
  useGetNonOriginalBillingDetailsQuery,
  useGetAWSFileManagementQuery,
  useGetChargeCodeQuery,
  useConvertZZZToChargeMutation,
  useUpdateUidWithoutStatusChangeMutation,
  useSplitUserChangeMutation,
  useShipmentRateCalculationMutation,
  useReShipmentRateCalculationMutation,
  useGetGroundCalculationDetailsQuery,
  useGetHSPCalculationDetailsQuery,
  useGetDupDetailsQuery,
  useCompulsoryPaymentMutation,
  useTemporaryRetrieveMasterTrackingMutation,
  useGroundShipmentRateCalculationMutation,
  useGenerateBillingBatchShipmentsMutation,
  useCreateRateTemplateMutation,
  useUpdateClientDateIntervalMutation,
  useAddNewFuelSurchargeMutation,
  useGetRuleExpressionsQuery,
  useCreateRuleExpressionMutation,
  useDeleteRuleExpressionMutation,
  useGetRuleExpressionsNamesQuery,
  useReevaluateBillingDetailsMutation,
  useGetAllCustomerUserGroupQuery,
  useUpdateBillingStatusMutation,
  useLabelReuseMutation,
  useGetBillingDetailsSummaryQuery,
  useGetInvoiceByNameQuery,
  useGetRuleExpressionNamesQuery,
  useGetUserByDepartmentQuery,
  useEditBillingBatchInfoMutation,
  useGetAccountReceivableQuery,
  useCreateAccountReceivableMutation,
  useEditingAccountReceivableMutation,
  useMakePaymentMutation,
  useUpdateAccountReceivableMutation,
  useAdjustSurchargesMutation,
  useDisputeSurchargesMutation,
  useGetAccountReceivableAndRiskControlOperationLogQuery,
} = apiSlice