import React from 'react';
import { Modal, Input, Typography, Space, Button } from 'antd';
const { Text } = Typography;
function SurchargeErrorDisputeModal ({ open, setOpen, disputeBasis, setDisputeBasis, onSubmit }) {
    return (
        <Modal
            title=""
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    回到Dispute附加费
                </Button>,
                <Button
                    key="dispute"
                    color="primary"
                    variant="solid"
                    onClick={() => {
                        onSubmit();
                        setOpen(false);
                    }}>
                    Dispute
                </Button>
            ]}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Text>
                    请填写Dispute原因:
                </Text>
                <Input.TextArea
                    value={disputeBasis}
                    onChange={
                        (e) => setDisputeBasis(e.target.value)
                    } />
            </Space>
        </Modal>
    )
}

export default SurchargeErrorDisputeModal;