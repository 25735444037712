import React, { useState, useEffect } from 'react';
import { Space, Table, Modal, notification } from 'antd';
import { CalculationDetailsPageColumns } from './HAndSpColumns';
import { 
    useGetAllCustomerUidQuery,
    useGetLogisticsServiceByNameQuery,
    useGetBillingInvoiceNamesQuery,
    useGetHSPCalculationDetailsQuery,
    useGetInvoiceByNameQuery,
} from '../../features/api/apiSlice';
import './HAndSpCalculationPage.css';
import {
    ProTable,
  } from '@ant-design/pro-components';
import HAndSpCalculationSearchBar from './HAndSpCalculationSearchBar';

function HAndSpCalculationPage () {
    const [searchData, setSearchData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [sorter, setSorter] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const { data, error, isLoading, isFetching, refetch } = useGetHSPCalculationDetailsQuery({...searchData, pageSize: pageSize, currentPage: currentPage, sortField: sorter.field,
        sortOrder: sorter.order,}, { refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (isLoading, isFetching) {
            const timeoutId = setTimeout(() => {
                notification.error({
                    message: '请求超时',
                    description: '服务器开小差了,请稍后重试',
                    duration: 0,
                });
            }, 60000);
            return () => clearTimeout(timeoutId);
        }
    }, [isLoading, isFetching]);
    const { data: logisticsService } = useGetLogisticsServiceByNameQuery();
    const { data: customerData, refetch: refetchUid } = useGetAllCustomerUidQuery();
    const [tableData, setTableData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const { data: invoiceNames, refetch: refetchInvoiceNames } = useGetInvoiceByNameQuery();
    const { data: billingInvoiceNames } = useGetBillingInvoiceNamesQuery();
    const [openSurcharge, setOpenSurcharge] = useState(false);
    const [openCost, setOpenCost] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [costContent, setCostContent] = useState(null);
    useEffect(() => {
        refetch();
        refetchUid();
        refetchInvoiceNames();
    }, [])

    useEffect(() => {
        if (!searchData.isDownload && data) {
            setTableData(data);
        }
    }, [data, searchData.isDownload]);

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        selectedRowKeys,
      };
    const getSelectedRowsData = () => {
        return tableData.results.filter(row => selectedRowKeys.includes(row.id));
    };
    const handleTableChange = (pagination, filters, sorter) => {
        setSorter(sorter);
      };

    const columns = CalculationDetailsPageColumns(setOpenCost, setCostContent)
    const surcharge_columns = [{
        title: '费用类型',
        dataIndex: 'type',
        key: 'type'
    }, {
        title: '金额',
        dataIndex: 'amount',
        key: 'amount'
    }];
    const renderModalTable = () => {
        return <Table columns={surcharge_columns} dataSource={modalContent} pagination={false} />;
    };
    const renderCostTable = () => {
        return <Table
            columns={surcharge_columns}
            dataSource={costContent}
            pagination={false}
            rowClassName={rowClassName}  // 应用自定义的行类名函数
        />;
    };
    const rowClassName = (record, index) => {
        // 检查当前行是否为最后一行
        return index === costContent.length - 1 ? 'last-row' : '';
    };
    return (
        <div>
            <Space
                direction="vertical"
                style={{ display: 'flex'}}>
                <HAndSpCalculationSearchBar
                    setSearchData={setSearchData}
                    customerData={customerData}
                    invoiceNames={invoiceNames}
                    logisticsService={logisticsService}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    selectedRows={tableData && tableData.results ? getSelectedRowsData() : []}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    billingInvoiceNames={billingInvoiceNames}
                    refetch={refetch}
                />
                <ProTable
                    search={false}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={tableData?.results}
                    loading={isLoading || isFetching}
                    onChange={handleTableChange}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        current: currentPage,
                        total: tableData?.count,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page);
                            setPageSize(pageSize);
                            setSelectedRowKeys([]);
                        },
                        onShowSizeChange: (current, size) => {
                            setCurrentPage(current);
                            setPageSize(size);
                            setSelectedRowKeys([]);
                        },
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: 'max-content' }}
                    options={{
                        reload: () => refetch(),
                    }}
                />
            </Space>
            <Modal
                title="附加费详情"
                open={openSurcharge}
                onOk={() => setOpenSurcharge(false)}
                onCancel={() => setOpenSurcharge(false)}
                width={800}
            >

                {renderModalTable()}

            </Modal>
            <Modal
                title="费用详情"
                open={openCost}
                onOk={() => setOpenCost(false)}
                onCancel={() => setOpenCost(false)}
                width={800}
            >
                {renderCostTable()}
            </Modal>
        </div>
    )
}

export default HAndSpCalculationPage;