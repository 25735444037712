import React, { useState, useEffect } from 'react';
import { Form, Modal, Select, Button, Row, Col, notification } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import SurchargeErrorDisputeModal from './SurchargeErrorDisputeModal';
import { useDisputeSurchargesMutation } from '../../features/api/apiSlice';
function DisputeSurchargeModal({ open, setOpen, chargeCodeData, selectedRowKeys, setSelectedRowKeys }) {
    const [form] = Form.useForm();
    const [submitDisputeBasis, setSubmitDisputeBasis] = useState('');
    const [surchargeErrorDisputeModalOpen, setSurchargeErrorDisputeModalOpen] = useState(false);
    const [disputeSurcharges, {
        isLoading: disputeSurchargesIsLoading,
        isSuccess: disputeSurchargesIsSuccess,
        isError: disputeSurchargesIsError,
        error: disputeSurchargesError,
    }] = useDisputeSurchargesMutation();

    useEffect(() => {
        if (disputeSurchargesIsError) {
            notification.error({
                message: '失败',
                description: disputeSurchargesError?.data?.error ?? 'Dispute附加费失败',
            });
        }
        if (disputeSurchargesIsSuccess) {
            setOpen(false);
            setSelectedRowKeys([]);
            form.resetFields();
            notification.success({
                message: '成功',
                description: 'Dispute附加费成功'
            });
        }
    }, [disputeSurchargesIsSuccess, disputeSurchargesIsError]);
    const modalOkHandler = () => {
        form.validateFields().then(values => {
            // check key description of each element in values.charges for duplicate
            // if there is duplicate, show error message
            const descriptionSet = new Set();
            let hasDuplicate = false;
            let disputeBasis = [];
            values.charges.forEach((item) => {
                if (descriptionSet.has(item.description)) {
                    hasDuplicate = true;
                } else {
                    descriptionSet.add(item.description);
                    disputeBasis.push(chargeCodeData.find((charge) => charge.id === item.description).description);
                }
            });
            disputeBasis = `Dispute以下附加费: ${disputeBasis.join(', ')}`;
            if (hasDuplicate) {
                notification.error({
                    message: '附加费重复',
                    description: '请删除重复的附加费',
                });
            } else {
                setSubmitDisputeBasis(disputeBasis);
                setSurchargeErrorDisputeModalOpen(true);
            }

        }).catch(errorInfo => {
            console.log(errorInfo);
        })
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 6 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 18 },
            sm: { span: 18 },
        },
    };

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 18, offset: 6 },
            sm: { span: 18, offset: 6 },
        },
    };

    const onSubmit = () => {
        form.validateFields().then(values => {
            const formData = new FormData();
            formData.append('disputeBasis', submitDisputeBasis);
            formData.append('charges', JSON.stringify(values.charges));
            formData.append('detail_ids', selectedRowKeys);
            disputeSurcharges(formData);
        }).catch(errorInfo => {
            console.log(errorInfo);
        })
    }

    return (
        <Modal
            title="dispute附加费"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={modalOkHandler}
            confirmLoading={disputeSurchargesIsLoading}
        >
            <Form
                form={form}
                name="dynamic_form_item"
                initialValues={{ charges: [""] }}
                {...formItemLayoutWithOutLabel}
            >
                <Form.List name='charges'>
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Row gutter={[0, 4]}>
                                    <Col span={22}>
                                        <Form.Item
                                            {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                            label={index === 0 ? '附加费' : ''}
                                            validateTrigger={['onChange', 'onBlur', 'onSubmit']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请输入或者删除该行",
                                                },
                                            ]}
                                            name={[index, 'description']}
                                            key={field.key}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '')
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                style={{ width: '90%' }}
                                            >
                                                {chargeCodeData?.map((item) => (
                                                    <Select.Option key={item.id} value={item.id} label={item.description}>
                                                        {item.description}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '60%' }}
                                    icon={<PlusOutlined />}
                                >
                                    添加需要Dispute的附加费
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
            <SurchargeErrorDisputeModal
                open={surchargeErrorDisputeModalOpen}
                setOpen={setSurchargeErrorDisputeModalOpen}
                disputeBasis={submitDisputeBasis}
                setDisputeBasis={setSubmitDisputeBasis}
                onSubmit={onSubmit}
                />
        </Modal>
    )
}

export default DisputeSurchargeModal;